@import "../../../assets/scss/variables";

.token__edit_form {
  @media screen and (max-width: $mobile-break) {
    padding-left: 24px;
  }
  @media screen and (max-width: 900px) {
    padding-left: 0;
  }
  @media screen and (max-width: 600px) {
    .token__gallery {
      .carousel {
        &-root {
          .carousel-slider {
            display: none;
          }
        }
        .thumbs-wrapper {
          margin-top: 0;
        }
      }
    }
  }
  .description {
    #field-name {
      font-size: 26px;
      font-weight: 500;
    }
    .token-information-form {
      &__textarea {
        margin-bottom: 5px;
      }
    }
  }
  .token__content {
    .specifications {
      .MuiGrid-root {
        .MuiTypography-root {
          font-weight: 500;
          color: $black;
        }

        .MuiGrid-item {
          .MuiFormControl-root {
            margin-bottom: 0;
          }
        }

        &.attributes {
          background-color: $white;

          .MuiFormControl-root {
            margin-bottom: 0;
          }
        }
      }
    }

    .documents {
      .token__upload_button {
        border-color: $red;
        color: $red;

        .icon {
          background-color: $red;
        }

        span {
          font-size: 14px;
          text-transform: capitalize;
        }
      }
    }
  }

  .token__gallery {
    .token__upload_cover_image {
      border-color: $red;
      color: $red;

      .icon {
        margin: 0;
        background-color: $red;
      }

      span {
        font-weight: 400;
        font-size: 14px;
        text-transform: capitalize;
      }

      .MuiBox-root {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      @media screen and (max-width: $mobile-break) {
        width: 100%;
        height: 40px;

        .MuiBox-root {
          flex-direction: row;

          span {
            &:last-child {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}
.token__edit_footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100% !important;
  background-color: $white;
  padding: 0 0 !important;
  box-shadow: 0 -1px 5px -1px rgba(0, 0, 0, 0.1);

  .token__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-left: 15px !important;
    padding-right: 15px !important;

    @media screen and (min-width: 560px) {
      margin-left: 100px !important;
    }

    button {
      width: calc(50% - 15px);
    }
  }
}
