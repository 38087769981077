.platform__menu {
  &--group {
    .MuiListItemText-root {
      .MuiTypography-root {
        color: #fff;
      }
    }
    .toggle {
      .MuiAvatar-root {
        margin-left: 15px;
      }
      &::after {
        content: "";
        width: 24px;
        height: 24px;

        position: absolute;
        right: 20px;
        top: 0;
        background-image: url(../../assets/icons/down.svg);

        @media screen and (max-width: 560px) {
          top: 15px;
        }
      }
    }
    .expand {
      &::after {
        transform: rotate(180deg);
      }
    }
  }
}
