@import '../../assets/scss/variables';

.logo {
    height: 70px;
    width: 70px;
    svg {
        height: 100%;
        max-width: 100%;
    }
    // background-image: url('../../assets/images/logo-v2.svg');
    mask-image: url('../../assets/images/logo-v2.svg');
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;

    background-color: $red;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
    background-position: center;
    &--short {
        // background-image: url('../../assets/images/logo-v2-short.svg');
        mask-image: url('../../assets/images/logo-v2-short.svg');
        background-color: $red;
    }
}

@media screen and (max-width: $mobile-break) {
    .logo {
        height: 60px;
    }
}