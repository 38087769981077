@import "../../assets/scss/variables";


.image-compare {
    &__tool {
        max-width: 800px;
        position: relative;
        // overflow: hidden;
        button {
            &.auto-align-switcher {
                width: 40px;
                height: 40px;
                position: absolute;
                top: 36px;
                right: 15px;
            }
        }
    }
    &__wrapper {
        position: relative;
        background-color: $black;
        border: 2px solid $black;
        border-radius: 8px;
        .labels {
            position: absolute;
            z-index: 100;
            width: 100%;
            top: 0;
            left: 0;
            > div {
                flex-basis: 50%;
                flex-shrink: 1;
                flex-grow: 1;
                color: $white;
                text-transform: uppercase;
                text-align: center;
                font-weight: normal;
                padding: 0.618em;
            }
        }
    }
    &__viewer {
        border-radius: 8px;
        overflow: hidden;
    }
}
@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.markproof {
    &__detail {
        background: $white;
        padding: 64px 32px;
        &.loading {
            opacity: 0.5;
            pointer-events: none;
            position: relative;
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 50px;
                height: 50px;
                border-radius: 50px;
                border: 5px solid grey;
                border-top-color: black;
                animation: loading 2s linear infinite;
                transform: translate(-50%, -50%);
                z-index: 99;
            }
        }
        .fullscreen-switcher {
            pointer-events: all;
            color: $white;
            line-height: calc(1.25rem*1.6);
            height: calc(1.25rem*1.6);
            font-size: 12px;
            position: absolute;
            padding: 0;
            top: 0; 
            left: 0;
            // transform: translate(-50%, -50%);
            z-index: 1299;
            padding: 1em;
        }
        .singularities {
            &__preview {
                position: absolute;
                bottom: calc(30px + 3em);
                left: 1em;
                z-index: 1300;
            }
            &__wrapper {
                pointer-events: none;
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 5;
            }
        }
        $lineWidth: 6px;
        .singularity {
            position: absolute;
            z-index: 400;
            aspect-ratio: 1;
            height: auto;
            background: linear-gradient($red, $red) top left, 
                        linear-gradient($red, $red) top left,
                        linear-gradient($red, $red) top right,
                        linear-gradient($red, $red) top right,
                        linear-gradient($red, $red) bottom left,
                        linear-gradient($red, $red) bottom left,
                        linear-gradient($red, $red) bottom right,
                        linear-gradient($red, $red) bottom right;
            background-size: $lineWidth 15%, 15% $lineWidth;
            background-repeat: no-repeat;
            > span {
                width: calc(100% - $lineWidth);
                height: calc(100% - $lineWidth);
                display: block;
                border: 1px dashed $red;
                margin: calc($lineWidth/2);
            }
        }
        #poi-file-upload {
            display: none;
        }
    }
    &__pois {
        flex-shrink: 0;
        flex-grow: 0;
        margin-right: 12px;
        justify-content: center;
        &--verified {
            margin-left: 12px;
            margin-right: 0;
        }
        .poi {
            width: 70px;
            height: 70px;
            overflow: hidden;
            padding: 0;
            margin-bottom: 8px;
            border: 2px solid $black;
            border-radius: 8px;
            &.selected {
                border: 2px solid $red;
            }
            .similarity {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $black;
            }
            img {
                height: 100%;
                width: auto;
            }
        }
    }

    &__verificator {
        justify-content: center;
        align-items: center;
    }

    &__detail.fullscreen {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1298;
        padding: 0;

        .fullscreen-switcher {
            left: calc(0.618*1.25rem);
            top: calc(0.618*1.25rem);
        }
        .markproof {
            &__pois {
                position: absolute;
                z-index: 1000;
                left: 1em;
                top: 50%;
                transform: translate(0, -50%);
                margin-left: 0;
                margin-right: 0;
                &--verified {
                    right: 1em;
                    left: auto;
                }
            }
        }
        .image-compare {
            &__wrapper {
                border-radius: 0;
                height: 100vh;
                display: flex;
                flex-direction: column;
                .labels {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    z-index: 1000;
                    pointer-events: none;
                }
            }
            &__tool {
                max-width: none;
                height: 100vh;
            }
            &__opacity-bar {
                &--wrapper {
                    height: 100%;
                    > span {
                        order: 2
                    }
                    svg {
                        order: 1;
                        margin-left: 0;
                        margin-bottom: 8px;
                    }
                }
                top: auto;
                bottom: 100px;
                left: 1em;
                max-width: auto;
                min-width: auto;
                max-height: 150px;
                min-height: 70px;
                height: 30%;
                width: auto;
            }
            &__actions {
                position: fixed;
                width: 100%;
                bottom: 0;
                margin: 0;
                padding: 28px 12px;
                align-items: flex-end;
                justify-content: flex-end;
                z-index: 1000;
                pointer-events: none;
                > div {
                    flex-basis: auto;
                }
                button {
                    border-radius: 0;
                    pointer-events: all;
                }
                .singularities {
                    margin-right: auto;
                }
            }
            &__viewer {
                flex-shrink: 1;
                flex-grow: 1;
                .image-compare__attributes {
                    bottom: 100px;
                    left: calc(1em + 30px);
                }
            }
        }
        .tool-guide {
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 8px;
            z-index: 1001;
        }
        .singularities {
            &__preview {
                bottom: 1em;
            }
        }
    }
    @media screen and (max-width: $mobile-break) {
        &__verificator {
            flex-direction: column!important;
        }
        &__pois {
            flex-direction: row!important;
            margin-bottom: 1em;
            &--verified {
                display: none;
            }
            .poi {
                margin-right: 8px;
            }
        }
    }
}