@import "../../assets/scss/variables";

.site.token-search {
	.search__field {
		&--select {
			label {
				padding: 0 1em;
				background-color: $white;
			}
			.MuiInputBase-input {
				position: relative;
				// backgroundColor: theme.palette.background.paper,
				border: 1px solid var(--unikbase-gray);
				padding: 10px 26px 10px 12px;
				// transition: theme.transitions.create([border-color, box-shadow]),
			}
		}
	}
    .page__content .MuiListItem {
        &-root {
            padding-left: 0.5em;
            &:nth-child(odd) {
                background-color: #E1E3E4;
                margin-bottom: 0;
            }
        }
    }
}