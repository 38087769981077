.page--tab-privacy-policies {
    .page__content > .MuiCardContent-root {
        .privacy-policy-wrapper > div > p:first-child {
            background-color: #E1E3E4;
            padding: 1em;
            margin: 16px 0;
        }
    }
    .list {
        &--number {
            li {
                padding-left: 0;
                margin-bottom: 2em;
            }
            h3 {
                font-size: 20px;
                font-weight: normal;
                padding-left: calc(1.5em + 20px);
                text-transform: uppercase;
                margin-bottom: 1em;
                &::before {
                    font-weight: bold;
                }
            }
        }
    }
}