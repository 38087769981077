:root {
    --unikbase-black: #{$black};
    --unikbase-gray: #{$gray};
	--unikbase-red: #{$red};
	--unikbase-primary: #{$red};
	--unikbase-disabled: #{$text-gray};
}
.color {
    &--black {
        color: $black;
    }
    &--gray {
        color: $gray;
    }
}