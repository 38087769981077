@import '../../assets/scss/variables';

.wallet {
  &__token-upload {
    background-color: $white;
    border: 1px solid $gray;
    border-radius: 8px;
    padding: 1em;
    color: $black;

    .token-details {
    }

    .token-error {
    }

    .token-success {
    }
  }
}

.dialog {
    &__import-tokens {
        .MuiDialogTitle-root {
            margin-bottom: 0;
        }
        .import__mapping-column {
            font-size: 14px;
            line-height: 24px;
            label {
                opacity: 0.7;
            }
            select {
                font-size: 14px;
                color: lighten($black, 20%);
            }
            .MuiFormControl-root {
                width: 50%;
            }
            .MuiInputBase-root {
                &::after {
                    border-width: 1px;
                }
                // border-bottom: 1px solid $black;
            }
        }
        .import-csv-error{
            color: $red;
            font-size: 14px;
        }
    }
}