@import "../../assets/scss/variables";

.form {
	&__verification {
		.digits {
			justify-content: space-between;
			margin-top: 2em;
			margin-bottom: 2em;
		}
		.MuiInputBase-root {
			border-bottom: 1px solid $black;
			width: calc((100% - 6*8px)/7);
			aspect-ratio: 1;
			input {
				font-size: 2em;
				font-weight: bold;
				text-align: center;
			}
			/* Chrome, Safari, Edge, Opera */
			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			/* Firefox */
			input[type=number] {
				-moz-appearance: textfield;
			}
		}
	}
}