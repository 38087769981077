@import "../../../assets/scss/_variables.scss";

#standaloneWebApp {
  .token-single {
    .MuiAppBar-root {
      display: none;
    }

    .site--platform {
      padding-top: 0;

      .container {
        padding-top: 0;

        .page__content {
          .token__information {
            .token__heading {
              padding-bottom: 0;
              display: none;

              .MuiBreadcrumbs-root {
                display: none;
              }
            }
          }

          .token {
            &__mobile-actions {
              position: fixed;
              left: 0;
              right: 0;
              bottom: 0;
              display: flex;
              justify-content: space-between;
              padding: 10px 20px 20px 20px;
              background-color: $white;
              box-shadow: 0 -1px 5px -1px rgba(0, 0, 0, 0.1);

              &.hidden {
                display: none;
              }

              > button {
                &:first-child {
                  padding-left: 5px;
                  padding-right: 5px;
                  min-width: 40px;

                  .icon {
                    margin-right: 0;
                  }

                  .MuiTypography-root {
                    display: none;
                  }
                  .MuiTouchRipple-root {
                    display: none;
                  }
                }
              }

              .icon__action {
                &.contained {
                  background-color: $white !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
