.confirm-dialog {
  &__close {
    width: 36px;
    height: 36px;
    margin-left: 50px;

    .icon {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 560px) {
    .MuiPaper-root {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
    }
  }
}
