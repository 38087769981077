@import "../../../assets/scss/variables";
@import "../../../assets/scss/flex";

.rdrCalendarWrapper {
    background-color: transparent;
    width: 100%;
    .rdrMonth {
        width: 100%;
    }
    .rdrDateDisplayWrapper {
        background-color: transparent;
        padding: 0;
        margin-bottom: 1em;
    }
    .rdrMonthAndYearWrapper {
        background-color: $white;
    }
    .rdrDateDisplay {
        margin: 0;
        .rdrDateInput {
            border-radius: 0;
            background-color: transparent;
            box-shadow: none;
            display: flex;
            align-items: center;
            flex-shrink: 0;
            margin-left: 0;
            input {
                background-color: $white;
            }
            &.rdrDateDisplayItemActive {
                border: none;
                input {
                    border: 1px solid $red;
                }
            }
            &:first-child {
                flex-basis: calc(50% - 1em);
                &::after {
                    content: " - ";
                    display: block;
                    right: 0;
                    margin-left: 1em;
                    margin-right: 1em;
                    color: $black;
                }
            }
            &:last-child {
                flex-basis: calc(50% - 3em);
            }
        }
    }
    .rdrMonths {
        background-color: $white;
    }
}
.tokens {
    &__list {
        background-color: $white;
        padding: 16px 0;
        overflow-x: scroll;
        position: relative;
        .MuiCheckbox-root {
            height: auto;
            width: 1.6rem;
            aspect-ratio: 1;
            padding: 0.3rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .icon {
                margin: 0;
            }
        }

        .tokens__list {
            &__empty {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 260px;
                height: 260px;
                margin: 50px auto;
            }
            &__boxes {
                width: 100%;
                height: 100%;

                background-image: url("../../../assets/images/boxes.svg");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }
        }
    }
    &__actions {
        padding: 0 24px;
        position: relative;
        flex-wrap: wrap !important;

        @media screen and (min-width: 1200px) and (max-width: 1390px) {
            &.extra-top {
                margin-top: 25px;
            }
        }
    }
    &__filters {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        flex-grow: 1;
        flex-basis: 50%;

        .icon-csv-download {
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url("../../../assets/icons/csv-download.svg");
        }
    }
    .filter {
        font-size: 14px;
        line-height: 1em;
        background-color: $white;
        flex-shrink: 0;
        flex-grow: 0;
        margin-right: 16px;
        .MuiInputBase-root {
            padding: 0;
            margin-left: 0;
        }
        input {
            height: 1em;
            padding: calc((30px - 1em) / 2);
            width: 0;
            padding-left: 0;
            padding-right: 0;
            transition: width ease-in-out 300ms;
        }
        button {
            font-size: 0.6182em;
            height: 40px;
            width: 40px;
            color: $black;
            background-color: $white;
            border-radius: 2px;
            padding: 10px;
            overflow: hidden;
        }
        &.open {
            input {
                width: 100%;
                height: 40px;
                padding-top: 0;
                padding-bottom: 0;
            }
        }
        .MuiFormControl-root {
            flex-shrink: 0;
            flex-grow: 0;
        }
        &__by-status {
            order: 1;
            margin-right: 16px;
            &--details {
                order: 3;
                flex-shrink: 0;
                flex-grow: 1;
                flex-basis: 100%;
                &.MuiCollapse-entered {
                    margin-top: 24px;
                    // padding: 1em 8px;
                    clear: both;
                }

                .MuiCollapse-wrapperInner {
                    @extend .flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    background-color: #c8cdcf;
                    padding: 1em;
                    .label {
                        flex-basis: 20ch;
                    }
                }
                .MuiFormControl-root {
                    margin-right: 2em;
                    .MuiFormGroup-root {
                        flex-direction: row;
                    }
                }
                @media screen and (max-width: 767px) {
                    flex-direction: column;
                    align-items: center;
                    .MuiCollapse-wrapperInner {
                        align-items: flex-start;
                        justify-content: flex-start;
                    }
                    .MuiFormControl-root {
                        .MuiFormGroup-root {
                            flex-direction: column;
                        }
                    }

                    &.MuiCollapse-entered {
                        &::after {
                            // content: none;
                        }
                    }
                }
            }
        }
        &__by-name {
            display: flex;
            flex-direction: row;
            order: 2;
            flex-shrink: 1;
            flex-grow: 0;
            max-width: calc(100% - 60px);
            background-color: $specGray;
            input {
                padding-left: 0;
            }
            button {
                width: 40px;
                height: 40px;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $specGray;
                .icon {
                    width: 20px;
                    height: 20px;
                    margin: 0;
                    background-color: $text-gray;
                }
            }
        }
    }

    &__view-switcher {
        &-inner {
            display: flex;
            background-color: $grayBorder;
        }
        button {
            color: #c2c2c2;
            background-color: transparent;
            text-transform: inherit;
            padding: 0;
            width: 40px;
            height: 40px;
            line-height: 1em;
            min-width: auto;
            &.active {
                color: $black;
                .icon {
                    background-color: $black;
                }
                background-color: #fff;
                border: 2px solid $grayBorder;
            }
            .icon {
                background-color: #c2c2c2;
                margin-right: 0;
                width: 20px;
                height: 20px;
            }
        }
    }

    @mixin statusColor($color) {
        color: lighten($color, 10%);
        &::before {
            background-color: lighten($color, 10%);
        }
    }
    .token {
        cursor: pointer;
        &__thumbnail {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            &:hover .token__favorite {
                display: block !important;
            }
        }
        &__favorite {
            position: absolute;
            display: none;
            font-size: 14px;
            color: lighten($color: $red, $amount: 20%);
            top: 0;
            left: 0;
            &.favorited,
            &:hover {
                color: $red;
            }
        }

        &__status {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: nowrap;
            &:not(.token__status--empty)::before {
                display: inline-block;
                content: " ";
                height: 8px;
                width: 8px;
                border-radius: 4px;
                margin-right: 8px;
            }
            text-transform: capitalize;
            &--incoming {
                @include statusColor($statusOrange);
            }
            &--managed,
            &--transferring {
                @include statusColor($statusGray);
            }

            &--declined,
            &--refused {
                @include statusColor($statusRed);
            }
            &--owned,
            &--accepted {
                @include statusColor($statusGreen);
            }
        }
        &__badges {
            .MuiChip {
                &-root {
                    background-color: $white;
                    // box-shadow: 1px 1px 1px 0px transparentize($color: $darken-gray, $amount: 0.3);
                    border-radius: 0;
                    width: 100%;
                    max-width: 20ch;
                    font-size: 10px;
                    padding-left: 10px;
                    padding-right: 10px;
                    &.token__operator-status {
                        &--incoming {
                            background-color: $statusOrange;
                        }
                        &--managed,
                        &--transering {
                            background-color: $statusGray;
                        }
                        &--refused {
                            background-color: $statusRed;
                        }
                        &--owned,
                        &--accepted {
                            background-color: $statusGreen;
                        }
                    }
                }
                &-label {
                    text-align: center;
                    font-size: 12px;
                }
            }
        }
    }
    &--grid-view {
        display: flex;
        flex-wrap: wrap;
        padding: 0 24px;
        .token {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            line-height: 24px;
            flex-basis: calc((100% - 20px * 3) / 4);
            width: calc((100% - 20px * 3) / 4);
            background-color: $white;
            margin-top: 0;
            margin-bottom: 1rem;
            margin-right: 20px;
            padding: 0;
            box-shadow: none;
            border: 1px solid $grayBorder;
            &:nth-child(4n) {
                margin-right: 0;
            }

            .Mui {
                &Card {
                    &Header {
                        &-root {
                            padding-bottom: 0;
                            padding-top: 0;
                            padding-left: 0;
                            padding-right: 0;
                            margin-bottom: 1rem;
                        }
                        &-title {
                            font-size: inherit;
                            line-height: inherit;
                        }
                    }
                    &Media {
                        &-root {
                            aspect-ratio: 3/2;
                            overflow: hidden;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: $white;
                            position: relative;
                            img {
                                height: 100%;
                                width: auto;
                            }
                            .MuiCheckbox-root {
                                position: absolute;
                                top: 8px;
                                right: 8px;
                            }
                        }
                    }
                    &Content {
                        &-root {
                            background-color: $white;
                        }
                    }
                    &Actions {
                        &-root {
                            padding-left: 0;
                            padding-right: 0;
                            padding-bottom: 0;
                            padding-top: 0;
                            align-items: right;
                            justify-content: flex-end;
                            display: flex;
                            margin-top: auto;
                            background-color: $text-gray;
                        }
                    }
                }
            }
            &__operator-status {
                margin-top: 0;
                padding: 2px 0;
                line-height: 1.5em;
                font-size: 14px;
                text-align: center;
                &--incoming {
                    background-color: $statusOrange;
                    color: $white;
                }
                &--managed,
                &--transferring {
                    background-color: $statusGray;
                    color: $white;
                }
                &--refused {
                    background-color: $statusRed;
                    color: $white;
                }
                &--owned,
                &--accepted {
                    background-color: $statusGreen;
                    color: $white;
                }
            }
            &__details {
                padding: 16px !important;
            }
            &__property {
                display: flex;
                justify-content: space-between;
                flex-wrap: nowrap;
                text-align: right;
                margin-bottom: 12px;
                &:first-child {
                    // min-height: calc(1.5em*3);
                }
                .label {
                    color: $text-gray;
                    display: flex;
                    flex-wrap: nowrap;
                    flex-shrink: 1;
                    flex-grow: 1;
                    white-space: nowrap;
                    text-align: left;
                    margin-right: 1em;
                }
            }
            @media screen and (max-width: 994px) and (min-width: 768px) {
                flex-basis: calc((100% - 20px) / 2);
                width: calc((100% - 20px) / 2);
                &:nth-child(2n) {
                    margin-right: 0;
                }
            }
            @media screen and (max-width: 767px) {
                flex-basis: 100%;
                width: 100%;
                margin-right: 0;
            }
            &__description {
                color: $text-gray;
                height: calc(24px * 4);
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &__detail {
                font-weight: $black;
                border-top: 1px solid $darken-gray;
                font-size: 14px;
                padding: 8px 0;
            }
            &__badges {
                position: absolute;
                right: 4px;
                top: 4px;
            }
            &__link {
                font-size: 12px;
                color: $black;
                border-radius: 0;
                background-color: $specGray;
                width: 100%;
                padding-left: 16px;
                padding-right: 16px;
                > span {
                    margin-right: auto;
                }
            }
        }

        @media screen and (max-width: 620px) {
            padding-left: 0;
            padding-right: 0;

            .tokens--header-toggle-all {
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: space-between;

                label {
                    margin-right: 0;
                }
            }
        }
    }
    &--list-view {
        tr {
            th,
            td {
                &:first-child {
                    padding-left: 24px;
                }
                &:last-child {
                    padding-right: 24px;
                }
            }
        }
        .MuiTableHead-root tr {
            background-color: $specGray;
            th {
                padding-top: 16px;
                padding-bottom: 16px;
                font-size: 14px;
                font-weight: 500;
                color: $text-gray;
            }
        }
        @media screen and (max-width: 900px) {
            .placeholder {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                td {
                    flex-basis: auto !important;
                    flex-grow: 1 !important;
                    .MuiSkeleton-root {
                        width: 100%;
                    }
                    .image {
                        position: absolute;
                        left: 1em;
                        top: 1em;
                        width: 100px !important;
                    }
                }
            }
        }
        .token {
            // &:nth-child(2n - 1) {
            //     td {
            //         background-color: $lighten-gray;
            //     }
            // }
            .MuiTable {
                &Cell {
                    &-root {
                        padding: 12px 20px 12px 0;
                        &:nth-child(2) {
                            max-width: 25%;
                        }
                        &::before {
                            display: none;
                        }

                        &:first-child {
                            padding-left: 24px;
                        }
                        &:last-child {
                            padding-right: 24px;
                        }
                    }
                }
            }
            &__thumbnail {
                width: 60px;
                min-width: 60px;
                position: relative;
                overflow: hidden;
                margin-right: 0.618em;
                &::after {
                    content: " ";
                    display: block;
                    padding-top: 100%;
                }
                img {
                    position: absolute;
                    width: 100%;
                    height: auto;
                    left: 50%;
                    top: 0;
                    transform: translate(-50%, 0);
                }
            }
            &__description {
                display: none;
            }
            &__link {
                font-size: 12px;
                color: $black;
            }
            @media screen and (max-width: 900px) {
                overflow: hidden;
                width: 100%;
                td {
                    position: initial;
                    padding-left: calc(100px + 2em) !important;
                    line-height: 1.618em;
                    flex-basis: 100% !important;
                    > div {
                        position: initial;
                        flex-direction: column-reverse !important;
                        align-items: flex-start;
                    }
                    &:first-child {
                        padding-top: 1em !important;
                    }
                    &:last-child {
                        padding-bottom: 1em !important;
                    }
                }
                &__thumbnail {
                    position: absolute;
                    left: 1em;
                    top: 1em;
                    width: 100px;
                }
                .token__link {
                    margin-left: calc(30%);
                    padding-top: 1em;
                }
            }
        }
    }
    &--empty {
        .wrapper {
            max-width: 400px;
            padding: 2em 0;
        }
        .tokens__list {
            .icon--empty {
                width: 30vw;
                height: 30vw;
                max-width: 400px;
                max-height: 400px;
                -webkit-mask-image: none;
                mask-image: none;
                background-image: url("../../../assets/icons/icon-wallet-empty.svg");
                background-color: transparent;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }
    &__pagination {
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        background-color: $white;
        border-top: 1px solid $grayBorder;
        height: 57px;
        z-index: 999;
        color: $text-gray;
        display: flex;
        align-items: center;
        padding-left: calc($sidebar-width + 12px);
        padding-right: 12px;
        label {
            font-size: 12px;
            line-height: 1.5em;
        }
        &__pages {
            margin-left: auto;
        }

        .MuiOutlinedInput-root {
            margin-left: 8px;
            margin-right: 8px;
            color: $text-gray;
            border-width: 1px;
            border-color: $grayBorder;
            font-size: 14px;
            border-radius: 2px;
            fieldset {
                border: 1px solid $text-gray;
                border-width: 1px !important;
                border-color: $text-gray !important;
            }
        }
        .MuiSelect-select {
            display: inline-block;
            padding: 5px 17px 5px 7px;
        }
        .MuiPagination-ul {
            li {
                button {
                    aspect-ratio: 1;
                    height: auto;
                    width: 32px;
                    background-color: $grayBorder;
                    border-radius: 0;
                    &.Mui-selected {
                        background-color: $red;
                        color: $white;
                    }
                }
            }
        }

        @media screen and (max-width: $mobile-break) {
            position: relative;
            margin-bottom: 80px;
            padding-top: 20px;
            padding-left: 0 !important;

            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            &__pages {
                margin: 20px auto 0 auto;
            }
        }
    }
}

@media screen and (max-width: 620px) {
    .wallet {
        .page {
            &__title {
                margin-bottom: 8px;
            }
            &__actions {
                padding-left: 16px !important;
                box-shadow: 0 -1px 5px -1px rgba(0, 0, 0, 0.1);
                .MuiBox-root {
                    .MuiBox-root {
                        flex-direction: row-reverse;

                        .MuiButton-root {
                            &:first-child {
                                margin-left: 16px;
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
        .tokens {
            padding-top: 0 !important;

            &--filter-selected-options {
                display: flex;
                flex: 1;
                overflow: auto;

                &-chips {
                    display: flex;
                    flex-direction: row;
                }
            }

            &__actions {
                width: 100vw;
                left: -16px;
                padding-bottom: 20px;
                border-bottom: 1px solid #e0e0e0;
            }
            &__list {
                padding-left: 0 !important;
                padding-right: 0 !important;
                overflow-x: hidden;
            }
            &--list-view {
                .token {
                    padding-left: 112px;
                    padding-top: 16px;
                    padding-bottom: 16px;
                    border-bottom: 1px solid #e0e0e0;
                    td {
                        padding-left: 0.5em !important;
                        padding-right: 0.5em !important;
                        padding-top: 0.125em;
                        padding-bottom: 0.125em;
                        align-items: center;
                        &::before {
                            flex-basis: 50%;
                            display: none !important;
                        }
                        &.MuiTableCell-paddingCheckbox {
                            display: block;
                            width: auto;
                            position: absolute;
                            right: 0;
                            top: 16px;
                            padding-top: 0 !important;
                            padding-right: 0 !important;
                            padding-left: 0 !important;
                            padding-bottom: 0 !important;
                            span {
                                margin-right: 0;
                            }
                        }

                        &.token {
                            &__name {
                                font-size: 16px;
                                font-weight: 500;
                            }
                            &__uuid,
                            &__tagRef,
                            &__lastUpdate,
                            &__operatorStatus,
                            &__offer,
                            &__origin {
                                display: none;
                            }
                            &__description {
                                font-size: 12px;
                                color: $text-gray;
                            }
                            &__statuses {
                                .token__statuses_row {
                                    width: 100%;
                                    margin-top: 20px;
                                    display: flex;
                                    flex-direction: row !important;
                                    align-items: center;
                                    justify-content: space-between;

                                    .token__status {
                                        flex: 1;
                                        justify-content: center;
                                        margin-right: 10px;
                                    }
                                }
                            }
                        }
                    }
                    &__thumbnail {
                        width: 96px;
                        left: 0px;
                        top: 16px;
                    }
                }
            }
            &--header-toggle-all {
                display: flex;
                align-items: center;
                justify-content: space-between;

                label {
                    margin-right: 0;
                }
            }
        }
    }
}

.site--platform-close {
    .tokens__list {
        padding: 0 24px;
        td {
            max-width: none;
        }
    }
    .tokens__pagination {
        padding-left: calc($header-height + 12px);
    }
}
