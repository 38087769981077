#standaloneWebApp {
  #TokenListWebApp {
    display: flex;
    flex-direction: column;

    .platform {
      &__sidebar {
        display: none;
      }
      &.wallet {
        header {
          display: none !important;
          height: 0;
        }

        main {
          padding-top: 0;
          overflow-y: visible;
        }

        .page {
          padding-top: 0;
          &__actions {
            display: none;
          }
          .wallet {
            &__tokens {
              .tokens {
                &__actions {
                  display: none;
                }
                &--header-toggle-all {
                  .MuiFormControlLabel-root {
                    display: none;
                  }
                }
                .token {
                  .MuiTableCell-paddingCheckbox {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
