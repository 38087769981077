@import "variables";

@import "reset";
@import "fonts";
@import "colors";

@import "layout";
@import "flex";
@import "utils";

@import "spinner";

a {
	cursor: pointer;
    text-decoration: none;
    color: $red;
}

.btn-link {
    cursor: pointer;
}

.page-anchor {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 1.618em;
    margin: 0 auto;
    background: lighten($light-red , $amount: 10%);
    color: $black;
    font-weight: 300;
    width: fit-content;
    padding:0 1em 0 0;
    border-radius: calc(1.618em/2);
    span {
        width: 1.618em;
        height: 1.618em;
        background-color: darken($light-red, 10%);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 0.5em;
        svg {
            font-size: 0.718em;
        }
    }
}

.site {
    font-family: 'Rubik', sans-serif;
    box-sizing: border-box;
    background-color: $black;
    color: $white;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    padding: 2em 0;
    section {
        position: relative;
    }
    &.single {
        overflow:auto;
        min-height: 100vh;
        height: auto;
    }
    &.login {
        padding: 70px 0;
    }

    &__header {
        margin-bottom: 2em;
    }
}

@media screen and (max-width: $mobile-break) {
    .MuiTable-root:not(.non-responsive) {
        display: block!important;
        width: 100%!important;
        thead {
            display: none;
        }
        tbody {
            display: block;
            tr {
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                position: relative;
            }
            td {
                display: flex;
                flex-direction: horizontal;
                flex-wrap: nowrap;
                padding: 0;
                line-height: 1.618em;
                flex-grow: 0;
                flex-basis: 95%;
                flex-shrink: 1;
                border: none;
                word-break: break-all;
                max-width: none!important;
                &::before {
                    display: block!important;
                    flex-basis: 30%;
                    font-weight: 500;
                    flex-shrink: 0;
                }
            }
            .collapse--button {
                position: absolute;
                right: 10px;
                top: 0;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .MuiCollapse-entered  {
                width: 100%;
            }
        }
    }
}