.token {
  &__document-upload {
    .MuiPaper-root {
      .MuiDialogTitle-root {
        .MuiBox-root {
          .MuiIconButton-root {
            width: 36px;
            height: 36px;

            .icon {
              margin-right: 0;
            }
          }
        }
      }

      @media screen and (max-width: 560px) {
        position: absolute;
        bottom: 0;
      }
    }
  }
}
