@import '../../../assets/scss/variables';

.singularity {
    &__preview {
        height: 60px;
        width: auto;
        aspect-ratio: 1;
        background-color: $black;
        overflow: hidden;
        position: relative;
        img {
            position: absolute;
        }
    }
}