.confirm-dialog {
  &__close {
    width: 36px;
    height: 36px;
    margin-left: 50px;

    .icon {
      margin-right: 0;
    }
  }

  .MuiDialogContent-root {
    padding-bottom: 5px;
  }
}
