@import "menu";
@import "form";
@import "variables";

.m {
    // margin
    &--l {
        margin-left: auto;
    }
    &--t {
        margin-top: auto !important;
    }
}

.w {
    &--100 {
        width: 100%;
        flex-basis: 100%;
    }
    &--50 {
        width: 50%;
        flex-basis: 50%;
    }
    &--30 {
        width: 30%;
        flex-basis: 30%;
    }
    &--70 {
        width: 70%;
        flex-basis: 70%;
    }

    &--40 {
        width: 40%;
        flex-basis: 40%;
    }
    &--60 {
        width: 60%;
        flex-basis: 60%;
    }
    &--80 {
        width: 80%;
        flex-basis: 80%;
    }
}
.bg {
    &--white {
        background: $white;
    }
    &--gray {
        background-color: $lighten-gray;
    }
}
.ellipsis_text {
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
        overflow: visible;

        & span {
            position: relative;
            background-color: white;
        }
    }
}
.layer {
    &--bg {
        z-index: 1;
    }
    &--mid {
        z-index: 50;
    }
    &--top {
        z-index: 999;
    }
}

.align {
    &--center {
        text-align: center;
        justify-content: center;
    }
    &--right {
        text-align: right;
        justify-content: right;
    }
}

.image {
    &--responsive {
        width: 100%;
        height: auto;
    }
    &--responsive-vertical {
        height: 100%;
        width: auto;
    }
}

.list {
    &--circle {
        list-style-type: circle;
        list-style-position: inside;
    }
}
.list--number {
    list-style: none;
    li {
        margin-bottom: 1em;
        padding-left: 2.5em;
        position: relative;
        h3 {
            &::before {
                content: "0" counter(list-item) "\a0\a0";
                font-weight: 500;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }
}

.dialog {
    .MuiDialogTitle-root + .MuiDialogContent-root {
        padding-top: 24px;
    }
    .MuiDialog {
        &Title-root {
            background-color: transparent;
            color: $black;
            text-align: left;
            font-weight: 500;
            position: relative;
            font-size: 24px;
            line-height: 1.5em;
            padding: 24px 24px 0 24px;
            position: relative;
            .MuiIconButton-root {
                font-weight: normal;
                color: $black;
                position: absolute;
                bottom: 0;
                right: 24px;
                padding: 0;
                .icon {
                    margin-right: 0;
                    width: 28px;
                    height: 28px;
                }
            }
        }
        &-paper {
            width: 600px;
        }
        &Actions {
            &-root {
                counter-reset: actionButtons;
                padding-right: 24px;
                padding-left: 24px;
                padding-bottom: 24px;
                button {
                    counter-increment: actionButtons;
                }
            }
        }
        &-paperFullScreen {
            .MuiDialogTitle-root button svg {
                font-size: 20px;
            }
        }
        &Content-root {
            h3 {
                font-weight: normal;
            }
        }
    }
    @media screen and (max-width: $mobile-break) {
        .MuiDialog {
            &-paper {
                min-width: 100vw;
            }
        }
    }
}

.icon {
    margin-right: 0.618em;
    display: inline-block;
    width: 1.618em;
    height: 1.618em;
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;
    background-color: $black;

    &__action {
        background-color: $red;
    }
    &--square {
        border-radius: 0;
        background-color: transparent;
        border: 1px solid $text-gray;
        height: 1rem;
        width: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &-check {
            background-color: $red;
            border: 1px solid $red;
            svg {
                color: $white;
                font-size: 8px;
            }
        }
    }
    &--faqs {
        mask-image: url("../../assets/icons/icon-faqs.svg");
    }
    &--help {
        mask-image: url("../../assets/icons/icon-help.svg");
    }
    &--terms-conditions {
        mask-image: url("../../assets/icons/icon-terms-conditions.svg");
    }
    &--privacy-policies {
        mask-image: url("../../assets/icons/icon-privacy-policies.svg");
    }
    &--profile {
        -webkit-mask-image: url("../../assets/icons/icon-profile.svg");
        mask-image: url("../../assets/icons/icon-profile.svg");
    }
    &--wallet {
        -webkit-mask-image: url("../../assets/icons/icon-wallet.svg");
        mask-image: url("../../assets/icons/icon-wallet.svg");
    }
    &--others {
        -webkit-mask-image: url("../../assets/icons/icon-others.svg");
        mask-image: url("../../assets/icons/icon-others.svg");
    }
    &--transfer {
        -webkit-mask-image: url("../../assets/icons/icon-transfer.svg");
        mask-image: url("../../assets/icons/icon-transfer.svg");
    }
    &--report {
        -webkit-mask-image: url("../../assets/icons/icon-certificate.svg");
    }
    &--share {
        -webkit-mask-image: url("../../assets/icons/icon-share.svg");
        mask-image: url("../../assets/icons/icon-share.svg");
    }
    &--dashboard {
        -webkit-mask-image: url("../../assets/icons/icon-dashboard.svg");
        mask-image: url("../../assets/icons/icon-dashboard.svg");
    }

    &--transactions {
        -webkit-mask-image: url("../../assets/icons/icon-transactions.svg");
        mask-image: url("../../assets/icons/icon-transactions.svg");
    }
    &--width {
        -webkit-mask-image: url("../../assets/icons/icon-width.svg");
        mask-image: url("../../assets/icons/icon-width.svg");
    }
    &--length {
        -webkit-mask-image: url("../../assets/icons/icon-length.svg");
        mask-image: url("../../assets/icons/icon-length.svg");
    }
    &--weight {
        -webkit-mask-image: url("../../assets/icons/icon-weight.svg");
        mask-image: url("../../assets/icons/icon-weight.svg");
    }
    &--depth {
        -webkit-mask-image: url("../../assets/icons/icon-depth.svg");
        mask-image: url("../../assets/icons/icon-depth.svg");
    }
    &--search {
        -webkit-mask-image: url("../../assets/icons/icon-search.svg");
        mask-image: url("../../assets/icons/icon-search.svg");
    }
    &--search-2 {
        -webkit-mask-image: url("../../assets/icons/icon-search-new.svg");
        mask-image: url("../../assets/icons/icon-search-new.svg");
    }
    &--slider {
        -webkit-mask-image: url("../../assets/icons/icon-slider.svg");
        mask-image: url("../../assets/icons/icon-slider.svg");
    }
    &--private {
        -webkit-mask-image: url("../../assets/icons/icon-private.svg");
        mask-image: url("../../assets/icons/icon-private.svg");
    }
    &--restricted {
        -webkit-mask-image: url("../../assets/icons/icon-restricted.svg");
        mask-image: url("../../assets/icons/icon-restricted.svg");
    }
    &--public {
        -webkit-mask-image: url("../../assets/icons/icon-public.svg");
        mask-image: url("../../assets/icons/icon-public.svg");
    }
    &--empty-wallet {
        -webkit-mask-image: none;
        mask-image: none;
        background-image: url("../../assets/icons/icon-wallet-empty.svg");
        background-color: transparent;
        background-repeat: no-repeat;
        background-size: contain;
    }
    &--camera {
        -webkit-mask-image: url("../../assets/icons/icon-camera.svg");
        mask-image: url("../../assets/icons/icon-camera.svg");
    }
    &--add-token {
        -webkit-mask-image: url("../../assets/icons/icon-add-token.svg");
        mask-image: url("../../assets/icons/icon-add-token.svg");
    }
    &--import-tokens {
        -webkit-mask-image: url("../../assets/icons/icon-import-tokens.svg");
        mask-image: url("../../assets/icons/icon-import-tokens.svg");
    }
    &--topup-balance {
        -webkit-mask-image: url("../../assets/icons/icon-topup-balance.svg");
        mask-image: url("../../assets/icons/icon-topup-balance.svg");
    }
    &--markproof {
        -webkit-mask-image: url("../../assets/icons/icon-markproof.svg");
        mask-image: url("../../assets/icons/icon-markproof.svg");
    }
    &--fingerprint {
        -webkit-mask-image: url("../../assets/icons/icon-fingerprint.svg");
        mask-image: url("../../assets/icons/icon-fingerprint.svg");
    }
    &--histories {
        -webkit-mask-image: url("../../assets/icons/icon-history.svg");
        mask-image: url("../../assets/icons/icon-history.svg");
    }
    &--histories {
        -webkit-mask-image: url("../../assets/icons/icon-histories.svg");
        mask-image: url("../../assets/icons/icon-histories.svg");
    }
    &--send-message {
        -webkit-mask-image: url("../../assets/icons/icon-send-message.svg");
        mask-image: url("../../assets/icons/icon-send-message.svg");
    }
    &--edit {
        -webkit-mask-image: url("../../assets/icons/edit.svg");
        mask-image: url("../../assets/icons/edit.svg");
    }
    &--share2 {
        -webkit-mask-image: url("../../assets/icons/share.svg");
        mask-image: url("../../assets/icons/share.svg");
    }
    &--export {
        -webkit-mask-image: url("../../assets/icons/export.svg");
        mask-image: url("../../assets/icons/export.svg");
    }
    &--note {
        -webkit-mask-image: url("../../assets/icons/note-favorite.svg");
        mask-image: url("../../assets/icons/note-favorite.svg");
    }
    &--document {
        -webkit-mask-image: url("../../assets/icons/document-text.svg");
        mask-image: url("../../assets/icons/document-text.svg");
    }
    &--wallet2 {
        -webkit-mask-image: url("../../assets/icons/wallet.svg");
        mask-image: url("../../assets/icons/wallet.svg");
    }
    &--sort {
        -webkit-mask-image: url("../../assets/icons/sort.svg");
        mask-image: url("../../assets/icons/sort.svg");
    }
    &--filter {
        -webkit-mask-image: url("../../assets/icons/filter.svg");
        mask-image: url("../../assets/icons/filter.svg");
    }

    &--list-view {
        -webkit-mask-image: url("../../assets/icons/icon-list-view.svg");
        mask-image: url("../../assets/icons/icon-list-view.svg");
    }

    &--grid-view {
        -webkit-mask-image: url("../../assets/icons/icon-grid-view.svg");
        mask-image: url("../../assets/icons/icon-grid-view.svg");
    }
    &--upload {
        background-color: white;
        -webkit-mask-image: url("../../assets/icons/upload.svg");
        mask-image: url("../../assets/icons/upload.svg");
    }

    &--calendar {
        -webkit-mask-image: url("../../assets/icons/icon-calendar.svg");
        mask-image: url("../../assets/icons/icon-calendar.svg");
    }

    &--close {
        -webkit-mask-image: url("../../assets/icons/icon-close-outline.svg");
        mask-image: url("../../assets/icons/icon-close-outline.svg");
    }
    &--photo {
        -webkit-mask-image: url("../../assets/icons/icon-gallery.svg");
        mask-image: url("../../assets/icons/icon-gallery.svg");
    }
    &--pdf {
        background-color: #ed1c24;
        -webkit-mask-image: url("../../assets/icons/pdf.svg");
        mask-image: url("../../assets/icons/pdf.svg");
    }
    &--circular_spinner {
        -webkit-mask-image: url("../../assets/icons/circular_spinner.svg");
        mask-image: url("../../assets/icons/circular_spinner.svg");
    }
    &--users {
        -webkit-mask-image: url("../../assets/icons/people.svg");
        mask-image: url("../../assets/icons/people.svg");
    }
    &--loader {
        -webkit-mask-image: url("../../assets/icons/loader.svg");
        mask-image: url("../../assets/icons/loader.svg");
    }
    &--profile {
        -webkit-mask-image: url("../../assets/icons/profile.svg");
        mask-image: url("../../assets/icons/profile.svg");
    }
    &--security-user {
        -webkit-mask-image: url("../../assets/icons/security-user.svg");
        mask-image: url("../../assets/icons/security-user.svg");
    }
    &--support-24h {
        -webkit-mask-image: url("../../assets/icons/support-24h.svg");
        mask-image: url("../../assets/icons/support-24h.svg");
    }
    &--logout {
        -webkit-mask-image: url("../../assets/icons/logout.svg");
        mask-image: url("../../assets/icons/logout.svg");
    }
    &--down {
        -webkit-mask-image: url("../../assets/icons/down.svg");
        mask-image: url("../../assets/icons/down.svg");
    }
    &--checkbox-circle {
        -webkit-mask-image: url("../../assets/icons/cb_circle.svg");
        mask-image: url("../../assets/icons/cb_circle.svg");
    }
    &--archive {
        -webkit-mask-image: url("../../assets/icons/archive.svg");
        mask-image: url("../../assets/icons/archive.svg");
    }
    &--mint {
        -webkit-mask-image: url("../../assets/icons/mint.svg");
        mask-image: url("../../assets/icons/mint.svg");
    }
}
