@import '../../../assets/scss/variables';


.token__transfer.dialog {
    .MuiDialog {
        &Title-root {
            margin-bottom: 16px;
        }
        &Actions-root {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            button {
                flex-basis: calc((100% - 16px) / 2);
                flex-grow: 1;
            }
        }
    }
    .token {
        &__share--main {
            .token {
                &__name {
                    font-size: 18px;
                    font-weight: 500;
                }
                &__description {
                    font-size: 12px;
                }
            }
        }
    }

    .receiver-address {
        label {
            font-weight: 500;
            font-size: 14px;
        }
        input {
            font-size: 14px;
            line-height: 14px;
            height: 14px;
            padding-top: 13px;
            padding-bottom: 13px;
        }
    }
    .awares {
        background-color: $noteBgColor;
        color: $noteColor;
        font-size: 12px;
        line-height: 1.5em;
        border: 1px solid $noteColor;
        padding: 12px;
        > div {
            padding: 0;
            margin-bottom: 1.5em;
            border-bottom: 0;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}