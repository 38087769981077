#standaloneWebApp {
  .token__share {
    .MuiDialog-container {
      box-sizing: content-box;

      .MuiPaper-root {
        max-height: 100%;
        overflow: hidden;
      }
      .MuiDialogActions-root {
        width: calc(100% - 48px);
      }
    }
  }
}
