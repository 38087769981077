.menu {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    li {
        flex-shrink: 1;
        flex-grow: 1;
    }
}