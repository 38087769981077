.loader-bar {
  position: relative;
  width: 50px;
  height: 50px;
  overflow: hidden;

  &-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    height: 90%;
    transform: translate(-50%, -20%);

    display: flex;
    align-items: center;
    justify-content: center;

    .icon--loader_bar {
      width: 100%;
      height: 100%;

      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("../../assets/images/bar_loader.svg");
    }
  }
}
