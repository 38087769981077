.circular-spinner {
  position: relative;
  width: 50px;
  height: 50px;

  .circle-square {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    height: 90%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    animation: circle-rotate 1s infinite linear;

    .icon {
      width: 100%;
      height: 100%;
      margin-right: 0;
      background-color: red;
    }
  }
}

/* CSS ANIMATION */
@keyframes circle-rotate {
  from {
    transform: translate(-50%, -50%);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
