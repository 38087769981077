@import "../../assets/scss/variables";

.markproofs {
	background-color: $white;
	padding: 24px;
	overflow-x: scroll;
	.markproof__verifications  {
        .MuiTable {
            &Body {
                &-root {
                    tr:nth-child(odd) {
                        background-color: $lighten-gray;
                    }
                }
            }
            &Row {
                &-root {
                    cursor: pointer;
                    &:last-child td {
                        border-bottom: none;
                    }
                    &:not(.MuiTableRow-head):hover {
                        background-color: $lighten-gray;
                    }
                }
            }
            &Cell {
                &-root {
                    padding-left: 4px;
                    padding-right: 4px;
                    padding-top: 0.618em;
                    padding-bottom: 0.618em;
                }
            }
        }
		.markproof__verifications {

        }
	}
    &--empty {
        .icon--empty-wallet {
            width: 300px;
            height: 300px;
        }
    }
    .MuiChip {
        &-root {
            background-color: $darken-gray;
            border-radius: 2px;
            width: 100%;
            max-width: 20ch;
            font-size: 10px;
            &.markproof__verification-status--rejected {
                background-color: #F47876;
            }
            
            &.markproof__verification-status--success {
                background-color: #ACD794;
            }
        }
        &-label {
            text-align: center;
            font-size: 12px;
        }
    }
}