@import "../../../assets/scss/variables";

.token {
	&__share {
		&--main {
			padding-top: 0 !important;
		}
		&-link .MuiPaper-root.MuiDrawer-paper,
		& .MuiPaper-root.MuiDialog-paper {
			.MuiDialogTitle-root {
				text-align: left;
				font-weight: 500;
				background-color: $white;
				color: $black;
				margin-bottom: 0;

				.MuiButtonBase-root {
					color: $black;
				}
			}
		}
		&--disabled {
			opacity: 0.3;
			pointer-events: none;
		}

		&-link .MuiPaper-root.MuiDrawer-paper {
			padding-left: 24px;
			padding-right: 24px;
			padding-top: 24px;
			padding-bottom: 24px;
			margin-left: auto;
			margin-right: auto;
			min-height: calc(1.618em * 5);
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
		}
		&__token-info {
			background-color: $specGray;

			span {
				font-weight: 500;
			}
			p {
				color: $text-gray;
			}
		}
		&__cover-image {
			width: 56px;
			height: 56px;
			border: 1px solid $gray;
		}
		.MuiDialog-paper {
			margin: 0;
		}
		.MuiDialogActions-root {
			width: 100%;
		}
		&-table {
			.table {
				width: calc(100% - 2px);
				&__head-cell {
					font-size: 9px;
					text-align: center;
					word-break: normal;
					line-height: 12px;
					padding-left: 4px;
					padding-right: 4px;
				}
				&__row {
					&.group {
						position: relative;

						.accordion-icon {
							position: absolute;
							top: 9px;
							right: 42px;
							cursor: pointer;

							&.expanded {
								transform: rotate(180deg);
							}
						}
						> td {
							padding-top: 4px;
							padding-bottom: 4px;
							border-top: 1px solid $gray;
							border-bottom: 1px solid $gray;
							&:first-child {
								border-left: 1px solid $gray;
								border-top-left-radius: 4px;
								border-bottom-left-radius: 4px;
							}
							&:last-child {
								border-right: 1px solid $gray;
								border-top-right-radius: 4px;
								border-bottom-right-radius: 4px;
								text-align: right;
							}
						}
					}
					&.invisible {
						display: none;
					}
					&.select-all {
						td {
							&:last-child {
								text-align: right;
								padding-right: 0;
							}
						}
					}
					&-item {
						.MuiTableCell-body {
							padding-top: 5px;
							padding-bottom: 0;

							.MuiCardContent-root {
								padding-bottom: 10px;
							}
						}
						border: none;
						> td {
							border: none;

							&:last-child {
								text-align: right;
							}
						}
						@media screen and (max-width: 600px) {
							.flex {
								flex-direction: column;
							}
							.MuiCardContent-root {
								padding-left: 0;
								padding-top: 1em;
								padding-bottom: 8px;
							}
						}
					}
				}
			}
		}

		&-btn {
			button {
				width: 100%;
			}
			&.have-shared-hash {
				button {
					width: calc((100% - 20px) / 2);
				}
			}
		}
		&-error {
			font-size: 11px;
			font-style: italic;
			color: #f00;
			padding: 10px 30px;
		}
	}
}
