@import "../../assets/scss/variables";

.site {
	&__header {
		margin-bottom: 70px;
	}
	&__logo {
		height: 70px;
	}
	&__navigation {
		align-items: flex-end;
		li {
			a {
				padding: 0 0.5em;
				font-weight: 400;
				color: inherit;
			}
			a:hover,
			&.active a {
				color: $red;
			}
		}
	}
	.menu__toggle {
		display: none;
		cursor: pointer;
		width: 1.125em;
		height: 1.125em;
		align-items: center;
		margin-left: auto;
		> span {
			display: block;
			width: 100%;
			height: 2px;
			background-color: $white;
			border-radius: 1px;
			&::after,
			&::before {
				content: " ";
				display: block;
				height: 2px;
				width: 100%;
				background-color: $white;
			}
			&::after {
				transform: translate(0px, 2px);
			}
			&::before {
				transform: translate(0px, -4px);
			}
		}
	}

	@media screen and (max-width: $mobile-break) {
		.site__header .container {
			align-items: center;
		}
		&__navigation {
			display: none;
			position: absolute;
			left: 0;
			top: 99px;
			width: 100%;
			background-color: $black;
			height: calc(100vh - 99px);
			z-index: 999;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
			padding: 2em 0;
			li {
				flex: 0;
			}
		}
		&.show-navigation &__navigation {
			display: flex;
		}
		.menu__toggle {
			display: flex;
		}
	}
}
