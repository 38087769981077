.custom-scrollbars__container {
  background-color: white;
  border-radius: 12px;
  display: grid;
  height: 100%;
  grid-template: auto / 1fr 30px;
  overflow: hidden;
  position: relative;
}

.custom-scrollbars__content {
  height: 100%;
  -ms-overflow-style: none;
  overflow: auto;
  padding: 0 1rem;
  scrollbar-width: none;
}

.custom-scrollbars__content::-webkit-scrollbar {
  display: none;
}

.custom-scrollbars__scrollbar {
  display: grid;
  gap: 1rem;
  grid-auto-flow: row;
  grid-template: auto 1fr auto / 1fr;
  padding: 1rem;
  place-items: center;
}

.custom-scrollbars__button {
  padding-left: 0;
  padding-right: 0;
  border: none;
  border-radius: 8px;
  background-color: transparent;
}

.custom-scrollbars__track-and-thumb {
  display: block;
  height: 100%;
  position: relative;
  width: 8px;
}

.custom-scrollbars__track {
  background-color: #ccc;
  border-radius: 12px;
  bottom: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 8px;
}

.custom-scrollbars__thumb {
  border-radius: 12px;
  background-color: #888;
  position: absolute;
  width: 8px;
}
