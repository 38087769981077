@import "../../../assets/scss/variables";

.form {
    &__topup_balance {
        .MuiInputAdornment-root {
            margin-right: 2px;
            p {
                height: 1.4375em;
                line-height: 1.4375em;
            }
        }
        .preview {
            margin-top: 4px;
            padding: 4px;
            border-bottom: 1px solid $lighten-gray;
            &:last-child {
                border-bottom: 0;
            }
            .upload_preview {
                display: block;
                width: 40px;
                height: 40px;
                overflow: hidden;
                margin-right: 4px;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
    &__amount {
        margin-bottom: 12px;

    }
}