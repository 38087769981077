@import '../../assets/scss/variables';
@import "~react-image-gallery/styles/scss/image-gallery.scss";

.gallery {
	width: 100%;
    &__item {
        display: block;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 400px;

        .zoom-image {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .carousel {
        &.carousel-slider {
            border: 1px solid $grayBorder;
        }

        .slide {
            position: relative;
            overflow: hidden;
            > span {
                background-size: contain;
                background-repeat: no-repeat;
            }
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                height: 100%;
                width: auto;
                transform: translate(-50%, -50%);
            }
        }
        .carousel-status {
            top: auto;
            right: auto;
            bottom: 16px;
            left: 16px;
            font-size: 14px;
            padding: 2px 10px;
            background-color: rgba($color: $black, $alpha: 0.5);
            text-shadow: none;
        }
        .thumbs-wrapper {
            margin-left: 0;
            margin-right: 0;
            .thumbs {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .thumb {
                    flex: 1;
                    overflow: hidden;
                    position: relative;
                    border: 1px solid $gray;
                    cursor: pointer;
                    max-width: calc(100% / 4);
                    &.selected {
                        border-width: 2px;
                        border-color: $orangeBorder;
                    }
                    &:after {
                        content: "";
                        display: block;
                        padding-top: 100%;
                    }
                    img {
                        position: absolute;
                        display: block!important;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
    &__viewer {
        position: relative;
        background-color: rgba(0, 0, 0, 0.6);
        &__close {
            position: absolute;
            top: 16px;
            right: 16px;
            width: 32px;
            height: 32px;
            border-radius: 16px;
            background-color: rgba(255, 255, 255, 0.4);

            display: flex;
            align-items: center;
            justify-content: center;

            .gallery__close_button {
                .icon {
                    margin-right: 0;
                    background-color: $white;
                    width: 22px;
                    height: 22px;
                }
                &:hover {
                    background-color: #337ab7;
                }
            }
        }
    }
}
