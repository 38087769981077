@import '../../assets/scss/variables';

.site__transactions {
    .site--platform {
        .page {
            display: flex;
            flex-direction: column;
            
            &__title {
                margin-bottom: 0;
            }
            // &__content > .MuiPaper-root > .MuiCardContent-root {
            //     overflow-x: scroll;
            // }
            .table--virtualized-rows {
                overflow: scroll;
            }
        }
    }
}
.transactions {
    &--empty {
        .icon--empty {
            width: 30vw;
            max-width: 300px;
            height: 30vw;
            max-height: 300px;
            mask-image: none;
            background-color: transparent;
            background-image: url('../../assets/icons/icon-transactions.svg');
            background-size: contain;
            background-repeat: no-repeat;
            margin: 1em 0;
        }
    }
    .MuiTableCell {
        &-head {
            padding-left: 1rem;
            padding-right: 1rem;
            padding-top: 1rem;
            padding-bottom: 1rem;
            background-color: $lighten-gray;
        }
        &-root {
            border: none;
            line-height: 1.618em;
            &:first-child {
                width: 30%;
            }
            &::before {
                display: none;
            }
        }
    }
    .transaction {
        &.even {
            .MuiTableCell-root {
                background-color: $lighten-gray;
            }
        }
        &__detail {
            border: none;
            border-top: 1px solid $white;
            padding: 0;
            line-height: 9;
            &.even {
                background-color: $lighten-gray;
                td:first-child {
                    color: $darken-gray;
                }
            }
            &.odd {
                border-top: 1px solid $lighten-gray;
                td:first-child {
                    color: $darken-gray
                }
            }

            .MuiCollapse {
                &-root {
                    line-height: 1.618em;
                    min-height: 0px;
                    height: 0px;
                }
            }
            .MuiTable {
                &Row-root{
                    &:first-child td {
                        padding-top: 1rem;
                    }
                    &:last-child td {
                        padding-bottom: 1em;
                    }
                }
                &Cell { 
                    &-root {
                        padding-top: 0;
                        padding-bottom: 0;
                        border: none;
                    }
                }
            }
        }
    }
    &__filter {
        .filter {
            &__type {
                flex-wrap: wrap;
                &--label{
                    width: calc(10ch + 12px);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 1.618em;
                }
                .transaction__types {
                    flex-grow: 0;
                    flex-shrink: 0;
                    flex-basis: calc(100% - 10ch - 12px);
                    > div {
                        flex-direction: row;
                        justify-content: space-between;
                        > label {
                            span {
                                font-size: 14px;
                                line-height: 1.618em;
                            }
                            width: calc((100% - 20px) / 3);
                            flex-basis: calc((100% - 20px) / 3);
                            max-width: 200px;
                            flex-grow: 0;
                            flex-shrink: 0;
                            margin-right: 0;
                        }
                    }
                    @media screen and (max-width: 900px) {
                        > div {
                            flex-direction: column;
                            > label {
                                width: 100%;
                                flex-basis: 100%;
                            }
                        }
                    }
                }

                .transaction__date-range {
                    &--block {
                        button {
                            text-transform: none;
                            font-size: 12px;
                            font-weight: 400;
                            &:not(:last-child)::after {
                                width: 1px;
                                height: 1em;
                                margin-left: 1em;
                                background-color: $black;
                                content: " ";
                                display: inline-block;
                            }
                            &:hover {
                                background-color: transparent;
                                text-decoration: underline;
                            }
                        }
                    }
                }
                @media screen and (max-width: 900px) {
                    flex-direction: row;
                    &--label {
                        width: 100%;
                        margin-bottom: 1em;
                    }
                    .transaction__types {
                        width: 100%;
                        flex-basis: 100%;
                    }
                    .transaction__date-range {
                        width: 100%;
                        &-block {
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: flex-start;
                            flex-wrap: wrap;
                        }
                    }
                }
            }
        }
    }

    @media screen  and (max-width: 900px) {

        tr {
            padding-left: 1em;
        }
        .transaction {
            padding-top: 1em;
            padding-bottom: 1em;
            &.even {
                background-color: $lighten-gray; 
            }
            & + tr {
                padding-left: 0;
                td {
                    flex-basis: 100%;
                    padding-right: 20px;
                }
            }
        }
        .MuiTableRow-root {
            &.transaction {
                &.even {
                    background-color: $lighten-gray; 
                }
            }
        }
    }
}
.transaction__delete-confirmation {
    word-wrap: break-word;
    .MuiTableCell-root {
        word-wrap: anywhere;
    }
}