@import '../../assets/scss/variables';

.site.login {
    overflow-y: scroll;
    .brand {
        margin-bottom: 5em;
    }
    .login {
        &-actions {
            margin-top: auto;
            margin-bottom: auto;
            > .container {
                width: 460px;
            }
        }
    }
}