@import "../../assets/scss/variables";

.table {
    &__managed-operator-users {
        margin-top: 24px;
        .empty-users {
            .icon.icon--empty-wallet {
                font-size: 300px;
            }
        }
        thead {
            background-color: $specGray;
            tr th {
                color: $text-gray;
            }
        }
        tbody {
            .password--placeholder {
                > span::before,
                > span::after,
                &::before,
                &::after {
                    content: "";
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    border-radius: 4px;
                    margin-right: 8px;
                    background-color: $black;
                }

            }
            .operator-user {
                &__status {
                    .status {
                        text-transform: capitalize;
                        &::before {
                            content: " ";
                            display: inline-block;
                            width: 8px;
                            height: 8px;
                            border-radius: 4px;
                            margin-right: 8px;
                            color: inherit;
                            background-color: $black;
                        }

                        &--active {
                            color: $green;
                            &::before {
                                background-color: $green;
                            }
                        
                        }
                        &--invite-pending {
                            color: $yellow;
                            &::before {
                                background-color: $yellow;
                            }
                        }
                    }
                }
                &__actions {
                    width: 2ch;
                    > .MuiIconButton-root {
                        font-size: 14px;
                        color: $black;
                    }
                }
            }
        }
    }
}