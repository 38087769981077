@import '../../assets/scss/variables';
:root {
  --rs-picker-value: #{$black};
  --rs-calendar-date-selected-text: #{$black};
  --rs-listbox-option-hover-text: #{$black};
  --rs-bg-active: #{$red};
  --rs-listbox-option-hover-bg: #{$red};
  --rs-calendar-cell-selected-hover-bg: #{$red};
  --rs-listbox-option-hover-bg: #{lighten($red, 40%)};
  --rs-state-hover-bg: #{lighten($red, 45%)};
  --rs-input-focus-border: #{$black};
  --rs-btn-primary-bg: #{$red};
  --rs-calendar-range-bg: #{lighten($red, 45%)};
  --rs-btn-primary-hover-bg: #{$red};
  --rs-btn-primary-active-bg: #{$red};
}
.site {
  &__transactions {
    & .site--platform .page__title::after {
      margin-bottom: 0;
    }
    .page__actions {
      margin-bottom: 0;
      width: 100%;
      .m--l {
        width: 100%;
      }
    }

    .rs-picker-default .rs-picker-toggle.rs-btn {
      border-radius: 0;
    }
    .transactions {
      &--empty {
        min-height: '50vh';
        padding: 2em 0;
        .icon {
          display: inline-block;
        }
      }

      &__filter {
        width: 100%;
        .filter {
          &__content {
            width: 100%;
            background-color: #c8cdcf;
            .MuiCollapse-wrapper {
              padding: 16px 32px;
            }
          }
        }
        .MuiIconButton-root {
          margin-bottom: 1em;
          border-radius: 2px;
          background-color: $white;
          .icon {
            margin-right: 0;
            width: 16px;
            height: 16px;
          }
          &.active {
            background-color: $darken-gray;
          }
        }
        .transaction__date-range {
          width: 70%;
          .divider {
            margin-right: 0.618em;
            font-size: 12px;
          }
          .MuiFormControl {
            border-radius: 0;
          }
          .MuiInputBase {
            &-root {
              border-radius: 0;
              background-color: $white;
              margin-right: 0.618em;
              width: 11ch;
            }
            &-input {
              padding-top: 0.618em;
              padding-bottom: 0.618em;
              border-radius: 0;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
