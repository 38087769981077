.html-content-wrapper {
    h3 {
        margin-top: 1em;
        margin-bottom: 0.618em;
    }
}

.page--tab-terms-conditions {
    .page__content > .MuiCardContent-root {
        padding: 1.618em;
        .tab__title  {
            font-weight: normal;
            font-size: 20px;
        }
        // .tab__title + div {
        //     background-color: #E1E3E4;
        //     padding: 1em;
        //     h5 {
        //         display: none;
        //     }
        // }
        .terms-conditions-wrapper {
            > div > p:first-child {
                background-color: #E1E3E4;
                padding: 1em;
            }
        }
        p {
            margin-top: 0.618em;
            margin-bottom: 0.618em;
        }
        h5, h3 {
            font-size: 1rem;
            font-weight: 500;
            margin-bottom: 1em;
        }
    }
}