.zoom-image {
  position: relative;
  overflow: hidden;
  cursor: zoom-in;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    display: block;
    width: 100%;
  }

  figure {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    opacity: 0;

    &:hover {
      opacity: 1;
    }

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &:hover {
    img {
      opacity: 0;
    }
  }
}
