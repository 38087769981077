@import "../../../assets/scss/variables";

.token {
    &__transactions {
        padding: 24px 0 !important;
        .transaction {
            display: flex;
            border: 1px solid $gray;
            margin-bottom: 8px;
            justify-content: space-between;
            color: $darken-gray;
            overflow: hidden;
            > div {
                &.MuiBox-root {
                    padding: 0 !important;
                }
            }
            > span {
                flex-shrink: 0;
                flex-grow: 0;
            }
            svg {
                font-size: 11px;
            }
            &__creation_date {
                flex-basis: 30%;
                min-width: 20ch;
                padding-right: 1em;
                font-size: 10px;
            }
            &__number {
                font-size: 12px;
                font-weight: 400;
                color: $darken-gray;
                line-height: 1.618em;
            }
            &__type {
                line-height: 1em;
                text-transform: capitalize;
                color: $black;
                font-weight: 400;
                flex-basis: 18ch;
                width: 18ch;
                margin-left: auto;
                margin-right: 12px;
                @media screen and (max-width: $mobile-break) {
                    margin-left: 0;
                    margin-right: auto;
                }
            }
            &__status {
                padding: 4px;
                color: #4fb100;
                background-color: #ddffc2;
            }
            &__detail {
                padding: 0 0 0.5em 0 !important;
                max-width: 100%;
                p {
                    overflow-wrap: anywhere !important;
                    word-wrap: break-word;
                }
            }
            &__details {
                padding: 1em;
                .MuiBox-root {
                    padding: 8px 0;
                }
                .amount {
                    padding: 8px!important;
                    > div.MuiBox-root { 
                        padding: 0;
                    }
                    &__total {
                        margin-top: 1em;
                        padding-top: 1em!important;
                        border-top: 1px solid $black;
                    }
                }
            }
        }
    }
}