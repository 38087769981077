@import "../../assets/scss/variables";


.tabs {
    margin-bottom: 24px;
	button.tabs__selector {
        padding-left: 28px;
        padding-right: 28px;
		width: calc((100% - 24px * 3) / 4);
		flex-grow: 0;
		flex-shrink: 0;
		margin: 0 24px 0 0 ;
		background-color: $white ;
		height: 70px;
		font-size: 16px;
		line-height: 16px;
		text-transform: none;
		border-radius: 0 ;
		&:nth-child(4n) {
			margin-right: 0 ;
		}
        text-transform: none;
        justify-content: flex-start;
        font-weight: normal;
        &.active {
            background-color: $black;
            color: $white;
            .icon {
                background-color: $white;
            }
        }
	}
    @media screen and (max-width: 900px) {
       flex-wrap: wrap; 
        button.tabs__selector {
            width: auto;
            // min-width: 30%;
            margin-right: 0;
            height: 40px;
            padding-left: 1em;
            padding-right: 1em;
            background-color: transparent;
            justify-content: center;
            .icon {
                margin-right: 12px;
            }
        }
    }
}
.page.page--tabs {
    &__content {
        .MuiPaper-root {
            border-radius: 0;
        }
        .MuiCardContent-root {
            padding: 24px;
        }
    }
}