.digits {
  &-row {
    position: relative;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;

    &-boxes {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-digit {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 60px;
      height: 60px;
      margin-left: 10px;
      margin-right: 10px;

      text-align: center;
      font-size: 2em;
      font-weight: bold;
      color: #000;
      border-bottom: 1px solid #000;
    }

    .MuiInputBase-root {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100%;
      border-bottom: none !important;

      color: transparent !important;
      text-align: center;

      input {
        text-align: center;
      }
    }
  }
}
