@import '../../assets/scss/variables';

.site {
    &.platform {
        padding: 0 0;
    }
    &--platform {
        min-height: 100vh;
        background-color: $white;
        color: $black;
        padding-top: $header-height;
        padding-left: $sidebar-width;
        padding-right: 24px;
        padding-bottom: $header-height;
        transition: left;
        transition-timing-function: ease-in-out;
        transition-duration: 200ms;
        overflow-y: scroll;
        > .container {
            padding-top: 16px;
            width: 100%;
            padding-left: 24px;

            @media screen and (max-width: $mobile-break) {
                padding-left: 0;
            }
        }

        &-close {
            padding-left: $header-height;
        }

        .btn {
            &-go-back {
                display: flex;
                align-items: center;
                text-decoration: none;
                svg {
                    font-size: 0.618em;
                    margin-right: 0.618em;
                }
            }
        }

        .page {
            &__actions {
                padding-left: 24px;
                margin-bottom: 24px;
                button {
                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
            &__title {
                font-size: 32px;
                line-height: 32px;
                margin-bottom: 35px;
                &::after {
                    margin-top: 28px;
                    content: " ";
                    display: block;
                    background-color: $black;
                    height: 2px;
                    width: 48px;
                }
            }
        }
        @media screen and ( max-width: 900px ) {
            padding-right: 24px;
            .container {
                margin-left: 24px;
                width: calc(100% - 24px);    
            }
        }
    }
}

.platform {
    &__appbar {
        box-shadow: none!important;
        border: none;
        width: auto!important;
        right: 0;
        left: $sidebar-width!important;
        transition-property: left, width!important;
        transition-timing-function: ease-in-out!important;
        transition-duration: 200ms!important;
        &--close {
            left: $header-height!important;
        }
    }
    &__toolbar {
        display: flex;
        background-color: $white;
        color: $black;
        justify-content: flex-end;
        height: $header-height;
        padding-right: 0!important;
        border-bottom: solid 1px $gray;
        .menu-button {
            width: 28px;
            height: 28px;
            margin: 10px;
        }
        .arrowdown-button {
            width: 28px;
            height: 28px;
            border-radius: 16px !important;
            margin: 10px;
            background-color: $gray;
            font-size: 12px;
            border-radius: 0;
            color: $black;
            &:hover {
                background-color: transparent;
            }
        }
        .logo {
            &--mobile {
                display: none;
            }
        }
        .user__informations {
            .wallet__balance {
                color: $text-gray;
            }
        }
        .header__wallet {
            display: flex;
            flex: 1;
            justify-content: flex-start;

            .topup__button {
                padding: 2px 12px;
                background-color: $lightOrange;

                .icon {
                    background-color: $red;
                }
            }

            @media screen and (max-width: $mobile-break) {
                h4 {
                    font-size: 24px;
                    margin-left: 12px;
                }
            }
        }
    }
    
    &__sidebar {
        border: none;
        .MuiDrawer-paper {
            width: $sidebar-width;
            background-color: $black;
            color: $white;
            border: none;
            transition-property: width;
            transition-timing-function: ease-in-out;
            transition-duration: 200ms;
        }
        &--close {
            .MuiDrawer-paper {
                width: $header-height;
            }
            .platform__menu .icon {
                margin-right: 0!important;
            }
            .MuiListItem-root {
                &.platform__menu--group {
                    padding-left: 12px!important;
                }
            }
            .MuiListItemButton-root {
                padding-left: 12px!important;
            }
        }
        &-header {
            background-color: $red;
            color: $white;
            height: $header-height;
            flex-basis: $header-height;
            flex-shrink: 0;
            flex-grow: 0;
            .logo {
                width: 100%;
                height: $header-height;
                background-color: $white;
                mask-size: calc(100% - 10px);
            }
            .toggle-sidebar {
                display: none;
            }
        }
        &-footer {
            display: flex;
            margin-top: auto;
            width: 100%;
            justify-content: center;
            .MuiIconButton-root {
                color: $white;
            }
        }
    }
    &__menu {
        .MuiListItem {
            &-root {
                white-space: normal;
                color: #8A9FA7;
                padding: 0 16px;
                margin-bottom: 8px;
                overflow: hidden;
                &:hover,
                &.active {
                    color: $red;
                    .icon {
                        background-color: $red;
                    }
                }
                .icon {
                    margin-right: 16px;
                    color: #8A9FA7;
                    display: inline-block;
                    height: $icon-size;
                    width: $icon-size;
                    min-width: auto;
                    background-color: #8A9FA7;
                }
                > .MuiListItemText-root {
                    > span {
                        padding-left: 24px;
                        padding-right: 24px;
                    }
                }
            }
            &Button-root {
                padding-top: 14px; 
                padding-bottom: 14px;
                padding-left: 24px;
                padding-right: 24px;
            }
            &Text-root {
                white-space: normal;
            }
        }
        &--group {
            text-transform: uppercase;
            margin-bottom: 16px;
            margin-top: 32px;
        }
    }

    .MuiCardContent-root .tab__title {
        font-weight: normal;
    } 
}
.usermenu {
    margin-top: 0!important;
    .MuiMenu-paper  {
        right: 28px !important;
        left: auto!important;
        min-width: 237px;
    }
    svg {
        margin-right: 12px;
    }
    ul {
        padding: 0;
        li {
            font-size: 14px;
            line-height: 1.5em;
            padding: 12px 16px;
            
            &:not(:last-child)::before {
                content: "";
                display: block;
                height: 1px;
                width: calc(100% - 32px);
                background-color: $grayBorder;
                position: absolute;
                bottom: 0;
                left: 16px;
            }
        }
    }
}

@media screen and (min-width: 561px) {
    .platform__sidebar {
        &:hover {
            .MuiDrawer-paper {
                width: $sidebar-width;
            }
            .MuiListItem {
                &Text-root  {
                    opacity: 1;
                }
                &Icon-root {
                    margin-right: 16px!important;
                }
            }
            .logo {
                width: 100%;
                height: $header-height;
                background-color: #FFFFFF;
                -webkit-mask-size: calc(100% - 10px);
                mask-size: calc(100% - 10px);
                mask-image: url('../../assets/images/logo-v2.svg');
            }

        }
    }
}
@media screen and (max-width: 560px) {
    $header-height-sm: 50px;
    .site {
        &--platform {
            padding-left: 1em;
            padding-right: 1em;
            padding-top: $header-height-sm;
            padding-bottom: $header-height-sm;
            > .container.page {
                margin-left: 0;
                margin-right: 0;
                width: 100%;
                padding-top: 20px;
            }
        }
    }
    .platform {
        .MuiToolbar-root {
            padding-left: 0;
        }
        &__toolbar {
            padding-left: 0;
            height: $header-height-sm;
            min-height: auto!important;
            .MuiAvatar-root {
                height: 40px;
                width: 40px;
                margin-right: 8px;
            }
            .arrowdown-button {
                margin-right: 1em;
                width: 40px;
                height: $header-height-sm;
            }
            .logo {
                &--mobile {
                    display: flex;
                    flex-direction: row;
                    margin-right: auto;
                    height: $header-height-sm;
                }
                &__wrapper {
                    height: $header-height-sm;
                    background-color: $red;
                    .logo {
                        height: $header-height-sm;
                        width: $header-height-sm;
                        mask-size: calc(100% - 15px);
                        background-color: $white;
                    }
                }
            }
        }
        &__appbar {
            left: 0!important;
        }
        &__sidebar {
            .MuiDrawer-paper {
                width: 0;
            }
        }

        .platform__sidebar-header {
            height: $header-height-sm;
            flex-basis: $header-height-sm;
            .platform__logo {
                height: calc($header-height-sm - 2*8px);
            }
        }
        .platform__menu {
            &--group {
                margin-top: 0;
                margin-bottom: 0;
                .MuiListItemText-root {
                    margin-top: 0;
                    margin-bottom: 0;
                }
                padding: 18px 0;
            }
            &--item {
                background-color: $light-black;
                margin-bottom: 0;
                .MuiListItemText-root {
                    padding-left: 1em;
                }
            }
        }
        &__mobile--open {
            .MuiDrawer-paper {
                width: 100vw;
                .MuiListItemText-root  {
                    opacity: 1;
                }
            }
            .platform__sidebar-header {
                height: $header-height-sm;
                flex-basis: $header-height-sm;
                min-height: auto;
                justify-content: flex-start;
                .toggle-sidebar {
                    display: block;
                    color: $white;
                    margin-left: auto;
                    font-size: 14px;
                    border-radius: 0;
                }
            } 
            .platform__logo {
                // background-image: url('../../assets/images/logo-v2.svg');
                mask-image: url('../../assets/images/logo-v2.svg');
                mask-size: contain;
                flex-basis: 150px;
                height: calc($header-height-sm - 2*8px);
            }
        }
    }
    .usermenu .MuiMenu-paper {
        right: 1em!important;
    }
}