@import "./variables";

.flex {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	&--nowrap {
		flex-wrap: nowrap;
	}
	&--grow {
		flex-grow: 1;
		flex-shrink: 1;
	}
	&--vertical {
		flex-direction: column;
        flex-wrap: nowrap;
	}
	&--horizontal {
		flex-direction: row!important;
        flex-wrap: nowrap;
	}
	&--justify {
		&-center {
			justify-content: center;
		}
		&-start {
			justify-content: flex-start;
		}
		&-end {
			justify-content: flex-end;
		}
		&-around {
			justify-content: space-around;
		}
		&-between {
			justify-content: space-between;
		}
	}
	&--align {
		&-top {
			align-items: top;
		}
		&-center {
			align-items: center;
		}
		&-start {
			align-items: flex-start;
		}
		&-end {
			align-items: flex-end;
		}
	}
    &--one-column-mobile {
        @media screen and (max-width: $mobile-break) {
            flex-direction: column!important;
            flex-wrap: nowrap;
        }
    }
}
