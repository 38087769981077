@import "../../assets/scss/variables";

.token {
    &-single &__information {
        display: grid;
        grid: "heading" "gallery" "desc" "content" "footer";
        grid-template-columns: 42vw 1fr;
        grid-template-areas:
            "heading heading"
            "desc content"
            "gallery content"
            "gallery footer";

        .token__heading {
            grid-area: heading;
            align-items: center;
            justify-content: space-between;

            .token__actions {
                padding: 0 0 !important;

                span {
                    text-transform: capitalize;
                }
            }

            @media screen and (max-width: $mobile-break) {
                padding-top: 0 !important;
            }

            &_sticky {
                position: fixed;
                bottom: 0;
                background-color: $white;
                z-index: 999;

                .token__actions {
                    button {
                        width: calc(50vw - 22px) !important;
                    }
                }
            }
        }

        .token__gallery {
            grid-area: gallery;

            @media screen and (max-width: $mobile-break) {
                padding-bottom: 0 !important;
                .MuiCard-root {
                    margin-bottom: 0 !important;
                }
            }
        }

        .token__desc {
            grid-area: desc;
            .description {
                margin-bottom: 0;
                .MuiCardHeader-title {
                    margin-top: 0;
                }
            }
        }

        .token__content {
            grid-area: content;
            .specifications {
                .MuiGrid-item {
                    color: $text-gray;
                    p {
                        color: $black;

                        &.ellipsis_text {
                            overflow: hidden;
                            -ms-text-overflow: ellipsis;
                            text-overflow: ellipsis;
                            white-space: nowrap;

                            &:hover {
                                overflow: visible;

                                & span {
                                    position: relative;
                                    background-color: white;
                                }
                            }
                        }
                    }
                    .input_price {
                        .MuiTypography-root {
                            width: 40px;
                            height: 40px;
                            text-align: center;
                            background-color: $specGray;
                            border: 1px solid $grayBorder;
                        }
                    }
                }
            }
            .documents-heading {
                padding: 0 !important;

                .document-buttons {
                    &.MuiBox-root {
                        padding: 0;
                    }
                    .document-button {
                        color: $text-gray;

                        .icon {
                            background-color: $text-gray;
                        }
                    }
                }
            }
            .history-heading {
                padding: 0 !important;

                .history-buttons {
                    display: flex;

                    &.MuiBox-root {
                        padding: 0;
                    }
                    .document-button {
                        color: $text-gray;

                        .icon {
                            background-color: $text-gray;
                        }
                    }
                }
            }
        }

        .cover-image {
            display: none;
            grid-area: footer;
        }
        @media screen and (max-width: 1320px) {
            flex-direction: column-reverse !important;
            grid-template-columns: 1fr;
            grid-template-areas: "heading" "gallery" "desc" "content" "footer";

            .token__heading {
                flex-direction: column !important;
                align-items: flex-start;

                .token__actions {
                    margin-top: 10px;
                    width: 100%;

                    @media screen and (max-width: 720px) {
                        .token-privacy {
                            &__button {
                                display: none;
                            }
                        }
                    }
                }
            }
            .cover-image {
                display: block;
            }
            &.loading {
                > div {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }
            }
            > .w--50,
            > .MuiSkeleton-root {
                width: 100% !important;
            }
        }
        > .MuiBox-root {
            padding-left: 24px !important;
            padding-right: 24px !important;
            flex-grow: 0;
            flex-shrink: 0;
            box-sizing: border-box;
            // .MuiBox-root {
            //     width: auto;
            // }
            @media screen and (max-width: $mobile-break) {
                width: calc(100vw - 90px);
                padding-left: 24px !important;
            }
            @media screen and (max-width: 900px) {
                width: calc(100vw - 110px);
                padding-left: 0 !important;
            }
            @media screen and (max-width: 560px) {
                width: calc(100vw - 40px);
                padding-left: 10px !important;
                padding-right: 10px !important;
            }
        }
    }
    &-single .page__actions {
        button {
            margin-bottom: 8px;
            margin-left: 8px;
        }
    }
    &__information {
        .MuiCard {
            &-root {
                border: none;
                margin-bottom: 1.618em;
                max-width: 100%;
                position: relative;
                &::after {
                    clear: both;
                    content: " ";
                    height: 1px;
                    display: block;
                }
            }
            &Header {
                &-root {
                    padding: 0.618em 0 !important;
                }
                &-avatar {
                    margin-right: 8px;
                }
                &-title {
                    font-size: 20px;
                }
                &-action {
                    margin-top: auto;
                    margin-bottom: auto;
                    margin-right: 0;
                    .MuiIconButton-root {
                        font-size: 16px;
                    }
                }
            }
            &-root.description {
                .MuiCardHeader-title {
                    font-size: 1.618em;
                    margin-top: 0.618em;
                    font-weight: 500;
                }
                .MuiCardContent-root {
                    padding-top: 0;
                    color: $text-gray;
                }
            }
            &Content {
                &-root {
                    padding: 1em 0 1em 0;
                    &:last-child {
                        padding-bottom: 0;
                    }

                    h6 {
                        color: $black;
                    }
                    a {
                        &.blockchainId {
                            font-size: 12px;
                            line-height: 12px;
                            word-wrap: break-word;
                            color: #00f;
                        }
                    }
                }
            }
            &Actions {
                &-root {
                    justify-content: right;
                    .MuiIconButton-root {
                        font-size: 16px;
                        // border: 1px solid $gray;
                        // border-radius: 4px;
                    }
                }
            }
        }
    }
    &-document__thumbnail {
        width: 40px;
        position: relative;
        display: inline-block;
        overflow: hidden;
        flex-shrink: 0;
        img {
            width: 100%;
            height: auto;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    &__attributes {
        &--tabs {
            @media screen and (max-width: $mobile-break) {
                .MuiTabs-flexContainer {
                    flex-wrap: wrap;
                    .MuiTab-root {
                        width: auto;
                        max-width: none;
                        margin-right: 0;
                        height: 40px;
                        padding-left: 1em;
                        padding-right: 1em;
                        background-color: transparent;
                        justify-content: center;
                        flex-basis: auto;
                        flex-grow: 0;
                        flex-shrink: 1;
                        .icon {
                            margin-right: 12px;
                        }
                    }
                }
            }
        }
        &-tabpanel {
            background-color: $lighten-gray;
            .MuiCardContent-root {
                background-color: $lighten-gray;
                padding-left: 24px;
                padding-right: 24px;
                color: $darken-gray;
                > p {
                    color: $black;
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 1em;
                    > span {
                        display: inline-block;
                        flex-basis: 20ch;
                        width: 20ch;
                        flex-shrink: 0;
                        color: $darken-gray;
                    }
                }
            }
            .MuiCard-root {
                background-color: transparent;
            }
        }
    }
    &__markproof-verifications {
        &.MuiList {
            &-root {
                padding-top: 1em;
                padding-bottom: 1em;
                padding-left: 1em;
                padding-right: 1em;
                .MuiListItem-root {
                    justify-content: space-between;
                    border: 1px solid $white;
                }
            }
        }
    }
    &__message {
        &--transfer {
            padding-left: 0;
            padding-right: 0;
            color: $noteColor;
            .MuiAlert {
                &-root {
                    padding: 12px;
                }
                &-message {
                    padding: 0;
                    font-size: 12px;
                    .MuiAlertTitle-root {
                        margin-bottom: 8px;
                        font-size: 14px;
                    }
                }
                &-icon {
                    background-color: $noteColor;
                    width: 26px;
                    height: 26px;
                    display: block;
                    flex-shrink: 0;
                    text-align: center;
                    align-items: center;
                    padding: 0;
                    line-height: 26px;
                    border-radius: 50%;
                    svg {
                        color: $noteBgColor;
                        font-size: 18px;
                        display: inline-block;
                        rotate: 25deg;
                    }
                }
            }
            .transfer-status {
                &__refused {
                    cursor: pointer;
                }
            }
        }
    }
}
.attributes {
    padding: 0 !important;
    justify-content: space-between;
    padding-top: 24px;
    margin-top: 10px !important;
    border-top: 1px solid $white;
    background-color: $specGray;
    .attribute {
        display: flex;
        flex-direction: row;
        padding-bottom: 8px;
        align-items: center;
        flex-wrap: nowrap;
        font-size: 0.875rem;
        min-height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        justify-content: space-between;
        .avatar-with-icon {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            justify-content: flex-start;
            flex-shrink: 0;
            flex-grow: 0;
            border: none;
            .icon {
                margin-right: 0.125em;
                height: 1em;
                width: 1em;
                flex-shrink: 0;
            }
        }
        .content {
            color: $black;
            text-align: right;
        }
        .MuiBox-root {
            margin-left: 10px;
            padding: 0 0 !important;

            .MuiTypography-root {
                &.unit {
                    width: 40px;
                    height: 40px;
                    text-align: center;
                    background-color: $specGray;
                    border: 1px solid $grayBorder;
                }
            }
        }
    }
    &.mobile-view {
        .attribute {
            flex-direction: column;
            // justify-content: flex-start;
            align-items: flex-start;
        }
    }
}
.documents {
    .MuiBox-root {
        padding: 0 !important;
    }
    .MuiList {
        &-root {
            padding-top: 0;
            padding-bottom: 0;

            li {
                border: 1px solid $gray;
                > div {
                    // height: 55px;
                    button {
                        height: 55px;
                    }

                    &.MuiListItemText-root {
                        display: flex;
                        flex-grow: 1;
                    }
                }
            }
        }
        &Item {
            &Text {
                &-root {
                    border: 1px solid $white;
                    text-align: left;
                    overflow: hidden;
                    flex: 1;
                    flex-grow: 0;
                    flex-shrink: 0;
                    flex-basis: calc(100% - 4.854em * 3 - 8px);
                    width: calc(100% - 4.854em * 3 - 8px);
                    font-size: 14px;
                    // height: calc(1.618em * 3);
                    > .MuiTypography-root {
                        padding: 8px;
                        display: flex;
                        flex-wrap: nowrap;
                    }
                    h3 {
                        padding-left: 8px;
                        color: $black;
                        // white-space: nowrap;
                        padding-right: 1em;
                        line-height: 1em;
                        > span {
                            font-size: 12px;
                            color: $darken-gray;
                        }
                    }
                    .icon {
                        margin-right: 0;
                    }
                    @media screen and (max-width: $mobile-break) {
                        flex-basis: 100%;
                        width: 100%;

                        h3 {
                            font-size: 14px;

                            > span {
                                font-size: 11px;
                            }
                        }
                    }
                }
            }

            @mixin mobile_action_buttons {
                position: absolute;
                top: 4px;
                right: 0;
                background-color: white;
                opacity: 0.6;
                button {
                    border: 0;
                    height: 20px;
                    width: 20px;
                    margin-right: 10px;
                    font-size: 12px;
                }
            }
            &SecondaryAction {
                &-root {
                    position: relative;
                    top: auto;
                    right: auto;
                    transform: none;
                    display: flex;
                    button {
                        height: calc(1.618em * 3);
                        width: calc(1.618em * 3);
                        margin-right: 0;
                        padding: 0;
                        border-radius: 0;
                        border: 1px solid $white;
                    }

                    @media screen and (max-width: $mobile-break) {
                        @include mobile_action_buttons;
                    }
                }
            }

            &-root {
                padding: 0;
                margin-bottom: 8px;
                flex-wrap: wrap;
                &.file-invalid .MuiListItemText-root {
                    text-align: left;
                    button {
                        color: $gray;
                    }
                }

                .token-document__sub-files {
                    order: 3;
                    flex-basis: 100%;
                    width: 100%;
                    padding-left: 58px !important;
                    padding-right: 8px !important;
                    > p {
                        line-height: 1em;
                        margin-top: 0;
                        font-size: 14px;
                    }
                    .MuiTypography-root {
                        font-size: 0.618em;
                        line-height: 1em;
                    }
                }
                &.mobile-view .MuiListItem {
                    &Text-root {
                        flex-grow: 1;
                        flex-shrink: 1;
                    }
                    &SecondaryAction-root {
                        @include mobile_action_buttons;
                    }
                }
            }
        }
    }
    .MuiIconButton-root {
        font-size: 14px;
    }
    > .MuiBox-root > .MuiBox-root {
        padding: 1em 0 !important;
    }
}
.document__preview {
    .document__viewer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 90vh;
        aspect-ratio: 2/3;
        overflow: hidden;
        // background-color: $gray;
        max-width: 100vw;
        max-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        &.image {
            height: auto;
            width: 90%;
            width: 90vw;
            height: 90vh;
            text-align: center;
            background-color: transparent;
            > * {
                pointer-events: all;
            }
            div {
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }
        }
        &--file {
            width: 90vw;
        }
        &--3D {
            width: 90vw;
            height: 100%;
        }
        .loading {
            position: absolute;
            top: calc(50% - 20px);
            left: calc(50% - 20px);
            z-index: 999;
        }
        .progress {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 999;
            width: 100%;
            &.obj-loading-progress {
            }
            &.texture-loading-progress {
                bottom: 4px;
            }
        }
        .pdf__navigation {
            position: absolute;
            bottom: 1em;
            left: 50%;
            transform: translate(-50%, 0);
            z-index: 999;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                display: block;
                cursor: pointer;
                font-size: 14px;
                line-height: 24px;
                width: 24px;
                border-radius: 12px;
                background-color: $white;
                color: $black;
                text-align: center;
                &:first-child {
                    margin-right: 0.5em;
                }
            }
        }
    }
    & &-close {
        color: #fff;
        position: fixed;
        right: 10px;
        top: 10px;
        font-size: 16px;
        min-width: auto;
        border: 1px solid $gray;
        z-index: 999;
    }
    .image__preview {
        max-height: 95vh;
        max-width: 95vw;
        padding: 0 0.5em;
        position: relative;
        img {
            max-width: 100%;
            height: auto;
        }
    }
}
