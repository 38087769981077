@import "../../../assets/scss/variables";

.gallery__edit {
  margin-bottom: 0 !important;
  .carousel {
    .thumbs-wrapper {
      margin-bottom: 0;
      .thumbs {
        .thumb {
          &:after {
            display: none;
          }

          .gallery {
            &__control {
              width: 104px;
              height: 104px;

              button {
                position: absolute;
                top: 5px;
                right: 5px;

                width: 20px;
                height: 20px;
                border-radius: 10px;
                color: $white;
                background-color: $black-fade;
              }
              .uploading-image-item {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                color: $white;
                background-color: $black-fade;

                text-align: center;
                font-size: 12px;
                padding-top: 15px;

                .spinner {
                  position: absolute;
                  top: 15px;
                  left: calc(50% - 24px);
                }
              }
            }
            &__cover_image {
              position: absolute;
              bottom: 5px;
              left: 5px;
              right: 5px;
              padding: 3px 0;
              background-color: $black-fade;

              color: $white;
              text-transform: uppercase;
              font-size: 12px;
              font-weight: 500;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
