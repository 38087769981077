@import "../../../assets/scss/variables";

.token__share {
  &.certificate {
    @media screen and (max-width: 560px) {
      .MuiDialog-container {
        .MuiPaper-root {
          max-height: 100vh !important;
          width: 100vw;
          height: 100vh;
        }
      }
    }
  }
}

.awares {
  > div {
    line-height: 1.618em;
    padding-bottom: 0.618em;
    padding-top: 0.618em;
    margin-bottom: 0;
    border-bottom: 1px solid $lighten-gray;
    &:last-child {
      border-bottom: 0;
    }
  }
}
