@import '../../assets/scss/variables';

.faqs {
    &-wrapper {
        .MuiList {
            &-root {
    
                .MuiCollapse-root {
                    padding-left: calc(24px + 1em);
                    padding-bottom: 1.618em;
                    flex-basis: 100%;
                    flex-shrink: 0;
                    hr {
                        margin: 0;
                        border-bottom: 1px solid #fff;
                        width: calc(100% - 1em);
                        /* background-color: white; */
                        margin-bottom: 1.618em;
                    }
                }
            }
            &ItemButton-root {
                border: none;
                padding-left: 1em;
                padding-right: 1em;
                flex-wrap: wrap;
                svg {
                    font-size: 12px;
                }
                .number {
                    font-weight: bold;
                    margin-right: 1em;
                }
            }
        }
    }
}

.page {
    &--tab-faqs {
        .MuiListItemButton-root .MuiListItemText-root span {
            text-transform: lowercase!important;
            padding-right: 2em;
            &::first-letter {
                text-transform: capitalize!important;
            }
        }
    }
}