@import '../../../assets/scss/flex';
@import '../../../assets/scss/variables';
.upload {
	margin-bottom: 0 !important;

	&--drag-drop {
		@extend .flex;
		@extend .flex--vertical;
		@extend .flex--align-center;
		@extend .flex--justify-center;
		cursor: pointer;
		position: relative;

		border: 1px dashed $border;
		background-color: $specGray;
		min-height: 120px;

		input[type="file"] {
			display: none;
		}
		.drag__file-element {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		label {
			p {
				u {
					color: $red;
				}
			}
		}
		.upload-button {
			width: 36px;
			height: 36px;
			border-radius: 18px;
			background-color: black;

			span {
				width: 20px;
				height: 20px;
				margin-right: 0;
				color: white;
			}
		}
	}
}