$black: #041c25;
$light-black: #0D3140;
$white: #FFFFFF;
$red: #EA5123;
$light-red: #EBAD99;
$gray: #EFEBE6;
$grayBorder: #E3E3E3;
$border: #c2c2c2;
$lighten-gray: #E5E7E8;
$gray-blue-shade: #E1E3E4;
$darken-gray: #838D92;
$text-gray: #7F898E;
$orangeBorder: #FF5D2D;
$lightOrange: #FFE8E1;
$black-fade: rgba(0, 0, 0, 0.5);
$noteColor: #C68507;
$noteBgColor: #FFFBE4;

$specGray: #F5F5F5;
$lengthColor:#E5F0EC;
$depthColor:#EDE5F0;
$widthColor:#F0EBE5;
$weightColor:#EDF0E5;

$mobile-break: 990px;

$header-height: 80px;
$sidebar-width: 240px;
$icon-size: 24px;

$green: #72C72C;
$yellow: #FFB800;

$statusGray: #7f7f7f;
$statusRed: #ff4d4a;
$statusGreen: #72c72c;
$statusOrange: #ffb800;
