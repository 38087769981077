@import "../../../assets/scss/variables";


.prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
.image-compare {
    &__tool {
        flex-grow: 1;
        flex-shrink: 1;
    }
    &__actions {
        margin-top: 8px;
        > div {
            flex-basis: 30%;
            flex-shrink: 0;
            flex-grow: 0;
            text-align: center;
        }
    }
    &__viewer {
        width: 100%;
        aspect-ratio: 3/2;
        position: relative;
        border: 2px solid $black;
        &::after {
            content: " ";
            clear: both;
        }
        img {
            &.source {
                z-index: 2;
                display: none;
                opacity: 0.7;
            }
            &.visible {
                z-index: 1;
                -webkit-user-drag: none; 
                -khtml-user-drag: none; 
                -moz-user-drag: none; 
                -o-user-drag: none; 
                user-drag: none; 
                pointer-events: none;
            }
        }
        .viewport {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;
            height: 100%;
            width: 100%;
            background-color: #ccc;
            background-color: rgba(0, 0, 0, 0.3);
            overflow: hidden;
            &--visible {
                height: 100%;
            }
        }
        .image {
            &--original {
                z-index: 2;
            }
            &--reference {
                z-index: 1;
            }
        }
        &.editting {
            border: 2px solid $red;
            .image-compare__attributes .action {
                display: block;
            }
        }
        // &:hover:not(.editting) {
        //     .image-compare__attributes .start-edit {
        //         display: block;
        //     }
        // }

        .image-compare__attributes {
           
        }
    }
    &__image {
        position: absolute;
        width: 100%;
        height: 100%;
        &.editting {
            img.source {
                display: block;
            }
        }
        img {
            width: 100%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform-origin: center;
            z-index: 2;
        }
        img.img--preload {
            width: 100%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform-origin: center;
            z-index: 1;

            &.hidden {
                display: none;
            }
        }
    }
    &__spacer {
        background-color: $black;
        height: 100%;
        position: absolute;
        width: 4px;
        top: 0;
        transform: translate(-2px, 0);
        z-index: 500;
        cursor: pointer;
        &::before {
            content: " ";
            width: 50%;
            height: 100%;
            position: absolute;
            top:0;
            left: 0;
            background-color: $red;
            z-index: 1;
            display: block;
        }
        &::after {
            content: " ";
            width: 16px;
            height: 16px;
            border-radius: 8px;
            background-color: $black;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            z-index: 2;
        }
    }
    &__opacity-bar {
        position: absolute;
        z-index: 11;
        left: 12px;
        bottom: calc(30px + 3em + 20px);
        width: 30%;
        max-width: 125px;
        min-width: 70px;
        &--wrapper {
            color: $white;
            font-size: 12px;
            svg {
                margin-left: 1em;
            }
        }
    }
    &__attributes {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 11;
        width: 100%;
        height: 100%;
        pointer-events: none;
        align-items: flex-end;
        padding: 1em;
        &.reference {
            justify-content: right;
            .MuiSlider {
                &-valueLabel {
                    right: auto;
                    left: 20px;
                    &::before {
                        left: 2px;
                        right: auto;
                        top: 2px;
                    }
                }
            }
        }
        button {
            pointer-events: all;
            font-size: 12px;
            width: 24px;
            height: 24px;
            line-height: 24px;
            background-color: $white;
            color: $black;
            text-align: center;
            &.active,
            &:hover {
                background-color: lighten($color: #000, $amount: 80);
            }
        }
        .start-edit {
            pointer-events: all;
            margin-right: 8px;
            display: none;
            line-height: 1em;
        }
        .action {
            pointer-events: all;
            display: none;
            position: relative;
            margin-right: 8px;
            .MuiSlider-root {
                display: none;
                position: absolute;
                bottom: calc(100% + 24px);
                height: 30%;
                min-height: 80px;
                max-height: 120px;
                padding:0;
                left: 50%;
                transform: translate(-50%, 0);
            }
            &.active {
                .MuiSlider-root {
                    display: block;
                }
            }
        }

    }
}