.mark-proof-verification-matching {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .header {
    text-align: center;
  }

  .header p {
    margin: 5px 0;
  }

  .code {
    padding: 5px;
    color: greenyellow;
    background-color: black;
    border-radius: 5px;
  }

  /* Using flexbox for the .content layout */
  .content {
    display: flex;
    /* justify-content: space-between; */
    width: 100%; /* Allow content to take the full width available */
  }

  /* Setting the properties for the divs inside the .content */
  .content > div {
    flex: 1; /* Each div will take equal space */
    position: relative; /* To contain the absolute positioned canvas */
    margin: 0 0px; /* Space between child divs */
  }

  .content > div h2 {
    text-align: center;
  }

  .content img {
    width: 100%;
    /* max-width: 1024px;
max-height: 485px; */
    display: block;
  }

  .content canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .hidden {
    display: none;
  }

  #alpha-slider {
    margin: 0;
    padding: 0;
    height: 25px;
    width: 100%;
  }

  #alpha-slider {
    position: relative;
    z-index: 10;
  }

  #superimposedCanvas {
    width: 100%;
  }

  .processed-images {
    display: flex;
    justify-content: space-between;
  }

  .processed-images img {
    width: calc(50% - 10px); /* Adjust based on the gap you want between images */
    margin: 5px;
  }

  .singularity-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .singularity-image img {
    top: 0;
    left: 0;
    /* width: calc(20% - 10px); */
    margin: 5px;
  }
}
