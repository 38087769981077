.onboarding-demo {
  margin: 50px 0;
  .title {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }
  form {
    margin: auto;
    width: 500px;
    display: flex;
    flex-direction: column;

    input[type="file"] {
      display: none;
    }

    .uploads {
      padding: 20px;
      margin: 5px 0 20px;
      border-radius: 5px;
      border: 1px dashed #ccc;
      text-align: center;
    }

    img {
      width: 200px;
    }

    .actions {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      align-items: center;
      justify-content: center;

      .error {
        text-align: center;
        color: red;
        font-size: 1.2em;
      }
    }
  }
}
