#standaloneWebApp {
  .transactions {
    &__filter {
      &__header {
        .export-csv {
          display: none;
        }
      }
    }
  }
}
