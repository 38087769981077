@import "../../assets/scss/variables.scss";

.seed-phrase-reveal-verify {
  &__head {
    background-color: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    .logo {
      width: 211px;
      height: 68px;

      background-color: $red;
    }
  }
  &__phrases {
    margin-top: 20px;
  }
  &__phrase {
    background-color: $specGray;
    padding: 8px;
    margin-bottom: 10px;
  }

  .MuiDialogActions-root {
    .MuiGrid-item {
      &:nth-child(2) {
        button {
          color: $darken-gray;
          text-decoration: underline;
        }
      }
    }
  }

  &.confirm-seed-phrase {
    .MuiGrid-item {
      .seed-phrase-reveal-verify__phrase {
        cursor: pointer;
      }
    }
  }
}
