.contact {
    &-form {
        > div {
            justify-content: space-between;
            display: flex;
            flex-wrap: wrap;
            max-width: 600px;
        }
        &__field {
            flex-shrink: 0;
            flex-grow: 0;
            .MuiOutlinedInput-root {
                border-radius: 0;
            }
            &.w--50 {
                width: calc(50% - 8px);
                flex-basis: calc(50% - 8px);
            }
        }
        button {
            border-radius: 0;
            min-width: 150px;
            margin-left: auto;
        }
    }
}
.platform--md {
    .contact-form {
        .w--50 {
            width: 100%;
            flex-basis: 100%;
        }
    }
}