@import "../../../assets/scss/variables";

.filter {
    &-menu { //This will be whole menu included backdrop and the real menu
        .MuiPaper-root { //real menu part
            padding-top: 16px;
            padding-bottom: 16px;
            min-width: 328px;
            .MuiList-root {
                padding-top: 0;
                padding-bottom: 0;
                .MuiMenuItem-root {
                    flex-wrap: wrap;
                    padding-left: 16px;
                    padding-right: 16px;
                    
                }
            }
            // Menu items

            .filter-menu__item {
                width: 100%;
                flex-basis: 100%;
                font-size: 14px;
                line-height: 20px;
                label {
                    display: inline-block;
                    margin-bottom: 12px;
                }
                &--checkboxes {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    .checkbox {
                        margin-right: 16px;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                    .MuiCheckbox-root {
                        padding: 0;
                        .icon {
                            margin-right: 8px;
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
                &--date {
                    .filter {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: row;
                        border: 1px solid $text-gray;
                    }
                    &-input {
                        margin-left: 0;
                        flex-shrink: 1;
                    }
                    button {
                        text-align: center;
                        .icon {
                            margin-right: 0;
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
                &--toggle {
                    width: 100%;
                    flex-basis: 100%;
                    .MuiAutocomplete-root,
                    .MuiFormControl-root {
                        width: 100%;
                        fieldset {
                            border-color: $text-gray;
                            border-width: 1px;
                        }
                    }
                }
            }
        }
        &__date-range-picker {
            .MuiPaper-root {
                padding: 16px;
                .MuiList-root {
                    padding-top: 0;
                    padding-bottom: 0;
                }
                .actions {
                    justify-content: space-between;
                    button {
                        width: calc((100% - 16px)/2);
                    }
                }
                .rdr {
                    &MonthAndYearWrapper {
                        padding-top: 0;
                        height: auto;
                    }
                }
            }
        }
        &__button {
            text-transform: capitalize!important;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 10px 16px;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: $text-gray!important;
            .icon {
                background-color: $text-gray;
                width: 20px;
                height: 20px;
                line-height: 20px;
                margin-right: 8px;
            }
            &--open {
                color: $black!important;
                .icon {
                    background-color: $black;
                }
            }
            svg {
                margin-left: 8px;
            }
        }

        .filter__by-name {
            display: flex;
            flex-direction: row;
            background-color: $white;

            input {
                padding-left: 0;
            }
            button {
                width: 40px;
                height: 40px;
                padding: 0;

                .icon {
                    width: 20px;
                    height: 20px;
                    margin: 0;
                    background-color: $text-gray;
                }
            }
        }
    }
}