@import "../../assets/scss/variables";

.tokenshare-layout {
  max-width: 920px;
  margin: auto;
  padding: 10px 50px 50px 50px;
  background-color: $white;

  @media screen and (max-width: $mobile-break) {
    padding: 10px 20px 50px 20px;
  }

  .tokenshare-header {
    display: flex;
    flex-direction: row;
    width: 100%;

    .MuiToolbar-root {
      padding-left: 0;
      width: 100%;

      .logo {
        &--mobile:first-child {
          display: block;

          .logo__wrapper {
            padding: 10px;
            background-color: $red;

            .logo {
              width: 60px;
              height: 60px;
              background-color: $white;
            }
            @media screen and (max-width: $mobile-break) {
              padding: 0;

              .logo {
                width: 50px;
                height: 50px;
              }
            }
          }
        }
      }

      .header {
        &__wallet {
          .MuiTypography-root {
            margin-left: 12px;
          }
        }
      }

      .MuiAvatar-root,
      .user__informations,
      button {
        display: none;
      }
    }
  }

  .tokenshare-body {
    .token__information {
      display: grid;
      grid: "heading" "gallery" "desc" "content" "footer";

      .token__gallery {
        grid-area: gallery;
      }

      .token__desc {
        grid-area: desc;

        .description {
          &__ownership {
            @media screen and (max-width: 600px) {
              h6 {
                font-size: 12px;
                .MuiTypography-root {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }

      .token__content {
        grid-area: content;
      }

      .cover-image {
        grid-area: footer;
      }

      @media screen and (max-width: $mobile-break) {
        .token__content,
        .token__desc {
          max-width: calc(100vw - 50px);
        }

        .token__content {
          .documents-heading {
            padding: 0 !important;

            .document-buttons {
              &.MuiBox-root {
                padding: 0;
              }
              .document-button {
                color: $text-gray;

                .icon {
                  background-color: $text-gray;
                }
              }
            }
          }
          .history-heading {
            .history-buttons {
              display: flex;

              &.MuiBox-root {
                padding: 0;
              }
              .document-button {
                color: $text-gray;

                .icon {
                  background-color: $text-gray;
                }
              }
            }
          }
        }
      }
    }
  }
}
