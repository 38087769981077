@import "../../../assets/scss/variables";

.form {
    &__add-new-token {
        .MuiInputAdornment-root {
            margin-right: 2px;
            p {
                height: 1.4375em;
                line-height: 1.4375em;
            }
        }
        .preview {
            margin-top: 4px;
            padding: 4px;
            border-bottom: 1px solid $lighten-gray;
            &:last-child {
                border-bottom: 0;
            }
            .upload_preview {
                display: block;
                width: 40px;
                height: 40px;
                overflow: hidden;
                margin-right: 4px;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        &__prefix {
            height: 40px;
            background-color: $specGray;
            border: 1px solid $grayBorder;
        }
        .specifications {
            .MuiGrid-root {
                .MuiTypography-root {
                    font-weight: 500;
                    color: $black;
                }

                .MuiGrid-item {
                    .MuiFormControl-root {
                        margin-bottom: 0;
                    }
                    .input_price {
                        .MuiTypography-root {
                            width: 40px;
                            height: 40px;
                            text-align: center;
                            background-color: $specGray;
                            border: 1px solid $grayBorder;
                        }
                    }
                }

                &.attributes {
                    background-color: $white;

                    .MuiFormControl-root {
                        margin-bottom: 0;
                    }

                    .attribute {
                        flex-direction: column;
                        align-items: flex-start;
                    }
                }
            }
        }
    }
}