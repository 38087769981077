@import "../../assets/scss/variables";

.transactions__filter {
    .filter-menu {
        &__item {
            &--date {
                .filter {
                    width: 200px;
                    flex-direction: row;

                    &__by-date-input {
                        .MuiInputBase-formControl {
                            height: 36px;
                        }
                    }
                }
                button {
                    text-align: center;
                    margin-bottom: 0 !important;
                    .icon {
                        margin-right: 0;
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }
    &.standalone {
        .transactions__filter__header {
            width: 100%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 20;

            display: flex;
            justify-content: space-between;

            padding-left: 16px;
            padding-right: 16px;
            background-color: #041c25;

            > .MuiIconButton-root {
                .icon {
                    background-color: $white;
                    margin-right: 0;
                }
            }
        }
        .filter__content {
            background-color: $white;
            padding-left: 16px;
            padding-right: 16px;
            padding-top: 10px;

            .filter__type {
                .transaction__types {
                    .MuiFormGroup-root {
                        .MuiFormControlLabel-root {
                            max-width: 50%;
                        }
                    }
                }
            }
        }
    }
    .transactions__filter__header {
        .icon-csv-download {
            width: 28px !important;
            height: 28px !important;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url("../../assets/icons/csv-download.svg");
        }
    }
}

.site__transactions .transactions__filter .transaction__date-range {
    flex-grow: 1;
}
.rdrCalendarWrapper {
    background-color: transparent;
    width: 100%;
    .rdrMonth {
        width: 100%;
    }
    .rdrDateDisplayWrapper {
        background-color: transparent;
        padding: 0;
        margin-bottom: 1em;
    }
    .rdrMonthAndYearWrapper {
        background-color: $white;
    }
    .rdrDateDisplay {
        margin: 0;
        .rdrDateInput {
            border-radius: 0;
            background-color: transparent;
            box-shadow: none;
            display: flex;
            align-items: center;
            flex-shrink: 0;
            margin-left: 0;
            input {
                background-color: $white;
            }
            &.rdrDateDisplayItemActive {
                border: none;
                input {
                    border: 1px solid $red;
                }
            }
            &:first-child {
                flex-basis: calc(50% - 1em);
                &::after {
                    content: " - ";
                    display: block;
                    right: 0;
                    margin-left: 1em;
                    margin-right: 1em;
                    color: $black;
                }
            }
            &:last-child {
                flex-basis: calc(50% - 3em);
            }
        }
    }
    .rdrMonths {
        background-color: $white;
    }
}
