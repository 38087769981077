@import "../../assets/scss/_variables.scss";

.token-filter-menu {
  &__documents,
  &__history {
    .filter-menu__item {
      width: 100%;
      flex-basis: 100%;
      font-size: 14px;
      line-height: 20px;
      label {
        display: inline-block;
        margin-bottom: 12px;
      }
      &--date {
        .filter {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          border: 1px solid $text-gray;
        }
        &-input {
          margin-left: 0;
          flex-shrink: 1;
        }
        button {
          text-align: center;
          .icon {
            margin-right: 0;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
