@import '../../../assets/scss/variables';

.singularities {
    &__viewport {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        z-index: 400;
    }
    &__preview {
        display: flex;
        flex-direction: row;
        .singularity {
            &__preview {
                margin-right: 12px;
                cursor: pointer;
                pointer-events: all;
                &.selected {
                    border: 1px solid $red;
                }
            }
        }
    }
}
.singularity__zoom {
    position: absolute;
    top: 1em;
    left: 1em;
    width: 30%;
    height: auto;
    aspect-ratio: 1;
    z-index: 9999;
    overflow: hidden;
    pointer-events: none;
    img {
        position: absolute;
        display: block;
    }
}