@import '../../../assets/scss/variables';

.page {
    &--tab-help {
        .tab__title {
            font-weight: normal;
        }
        .contact-form {
            &__field {
                background-color: #E1E3E4;
                border: none;
                &:not(.contact-form__field-message)  label {
                    color: $black;
                }
                label {
                    font-size: 14px;
                    &.Mui-focused {
                        top: -4px;
                    }
                }
                fieldset { 
                    border: none;
                }
            }
        }
    }
}