#loading {
  position: relative;
  width: 50px;
  height: 50px;
}

#loading .circle-square {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  transform: translate(-50%, -50%);
}

#loading .circle-square {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  animation: circle-rotate 1.5s infinite linear;
}

.red,
.green,
.yellow,
.blue {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  margin: 2px;
}

.red {
  background-color: #ea4335;
}

.blue {
  background-color: #4285f4;
}

.green {
  background-color: #34a853;
}

.yellow {
  background-color: #fbbc05;
}

/* CSS ANIMATION */
@keyframes circle-rotate {
  from {
    transform: translate(-50%, -50%);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
