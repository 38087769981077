@import '../../assets/scss/variables';

.site.token-single {
    height: auto;
    padding-top: 0;
    .site__header {
        margin-bottom: 0!important;
        position: sticky;
        width: 100vw;
        z-index: 999;
        background-color: $black;
        padding: 30px 0 ;
    }

    .token {
        &__information {
            background-color: $white;
            color: $black;
            .cover-image {
                height: 30vh;
                position: relative;
                width: 100%;
                overflow: hidden;
                img {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    height: 100%;
                    width: auto;
                }
            }
            .MuiBox {
                &-root {
                    padding: 1em 0;
                }
            }
            .title {
                padding: 0.5em 0;
                border-bottom: 1px solid $gray;
                margin-bottom: 0.5em;
                svg {
                    font-size: 0.9em;
                    margin-right: 0.5em;
                    vertical-align: middle;
                }
            }
        }
		&-search {
			.MuiNativeSelect-root {
				display: none;
			}
		}
    }

	@media screen and (max-width: 767px) {
		.site__header {
			padding: 24px 0;
		}	
		.site__logo {
			height: 60px;
		}
	}

}