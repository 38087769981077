@import '../../assets/scss/variables';
@import '../../assets/scss/flex';

.wallet {
    .site--platform {
        padding-right: 0;


        &-close {
            .page__actions {
                position: fixed;
                z-index: 1199;
                bottom: 0;
                top: auto;
                background-color: $white;
                width: 100%;
                margin: 0;
                padding-top: 8px;
                padding-bottom: 16px;
                padding-right: 16px;
                padding-left: calc($header-height + 16px);
                justify-content: space-between;
                > div { 
                    width: 100%;
                    > .flex {
                        justify-content: space-between;
                        flex-wrap: nowrap;
                        button {
                            flex-basis: calc((100% - 16px)/2);
                            flex-shrink: 1; 
                            flex-grow: 0;
                            margin-left: 0;
                            margin-right: 16px;
                            margin-top: 0;
                            margin-bottom: 0;
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
            .tokens {
                &__filters {
                    justify-content: flex-end;
                    flex-direction: row-reverse;
                    flex-wrap: nowrap;
                }
            }
        }
    }
    .page {
        @extend .flex;
        @extend .flex--vertical;
        position: relative;
        padding-left: 0;
        &__actions {
            z-index: 99;
            position: absolute;
            top: 16px;
            left: 0;
            // top: calc(35px);
            button {
                margin-bottom: 8px;
                margin-left: 8px;
            }
        }
        &__title {
            margin-bottom: 0.618em;
        }
    }
    .tokens {
        padding-top: 16px;
    }
}
