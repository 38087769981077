@import "../../assets/scss/variables";

.change-password-form {
  .password-requirements {
    background-color: $specGray;

    .icon {
      width: 16px;
      height: 16px;
      margin-right: 5px;

      &.checked {
        background-color: $green;
      }
    }
  }
}
