.form {
    &--disabled {
        h6,
        .MuiFormControlLabel-root,
        .MuiFormControl-root {
            pointer-events: none;
            opacity: 0.5;
        }
    }
	&__signup {
		position: relative;
		.MuiInputLabel-root  {
			background-color: #fff;
			padding-left: 0.382em;
			padding-right: 0.382em;
		}
        &--tpk {
            
            .token__details {
                @media screen and (min-width: 768px) {
                    flex-wrap: wrap;
                    padding-top: 1em;
                    padding-bottom: 1em;
                    .MuiCardMedia-root {
                        flex-shrink: 0;
                        flex-grow: 1;
                        margin-left: 1em;
                    }
                    .token__description {
                        flex-basis: 70%;
                    }
                }
                @media screen and (max-width: 767px) {
                    flex-direction: column;
                    flex-wrap: nowrap;
                    .MuiCardMedia-root {
                        width: 100%;
                        height: auto;
                        aspect-ratio: 3/2;
                    }
                }
            }
            .creation-loading {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(255, 255, 255, 0.9);
                // pointer-events: none;
                z-index: 999;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                .loading-progress {
					height: 8px;
                    // border-radius: 4px;
                    margin-top: 1em;
                }
            }
        }
	}
	&--logging-in {
		display: block;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		background-color: rgba(255, 255, 255, 0.7);
		cursor: none;
		position: absolute;
		z-index: 999;
		
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
}