@import "../../../assets/scss/variables";

.token {
	&__share {
		&--main {
			padding-top: 0 !important;
		}
		&-link .MuiPaper-root.MuiDrawer-paper,
		& .MuiPaper-root.MuiDialog-paper {
		}
		&--disabled {
			opacity: 0.3;
			pointer-events: none;
		}

		&-link .MuiPaper-root.MuiDrawer-paper {
			padding-left: 24px;
			padding-right: 24px;
			padding-top: 24px;
			padding-bottom: 24px;
			margin-left: auto;
			margin-right: auto;
			min-height: calc(1.618em * 5);
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
		}

		.MuiDialog-paper {
			margin: 0;
		}
		.MuiDialogActions-root {
			width: 100%;
		}
		&-table {
			.table {
				width: calc(100% - 2px);
				&__head-cell {
					font-size: 9px;
					text-align: center;
					word-break: normal;
					line-height: 12px;
					padding-left: 4px;
					padding-right: 4px;
				}
				&__row {
					&.group > td {
						border-top: 1px solid $gray;
						border-bottom: 1px solid $gray;
						&:first-child {
							border-left: 1px solid $gray;
							border-top-left-radius: 4px;
							border-bottom-left-radius: 4px;
						}
						&:last-child {
							border-right: 1px solid $gray;
							border-top-right-radius: 4px;
							border-bottom-right-radius: 4px;
						}
					}
					&-item {
						border: none;
						> td {
							border: none;
						}
						@media screen and (max-width: 600px) {
							.flex {
								flex-direction: column;
							}
							.MuiCardContent-root {
								padding-left: 0;
								padding-top: 1em;
								padding-bottom: 8px;
							}
						}
					}
				}
			}
		}

		&-btn {
			button {
				width: 100%;
			}
			&.have-shared-hash {
				button {
					width: calc((100% - 20px) / 2);
				}
			}
		}
		&-error {
			font-size: 11px;
			font-style: italic;
			color: #f00;
			padding: 10px 30px;
		}
	}
}
