.require-password-dialog {
    > .MuiDialog-container > .MuiPaper-root {
        width: 90vw;
    }
    .MuiFormControl-root {
        width: 100%;
    }

    .MuiDialogTitle-root button {
        position: absolute;
        right: 10px;
        top: 10px;
    }
}
