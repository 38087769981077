@import '../../assets/scss/variables';
.profile {
    &__settings {
        &-tabs {
            .MuiTab-root {
                font-size: 18px;
                line-height: 18px;
                padding-top: 16px;
                padding-bottom: 16px;
                padding-left: 16px;
                padding-right: 16px;
                text-transform: none;
                &.Mui-selected {
                    border-color: transparent;
                }
                &:first-child {
                    padding-left: 0;
                }
            }
        }
        &-group {
            padding-top: 24px;
            padding-bottom: 24px;
            .setting__dialog-title {
                display: block;
                margin-bottom: 8px;
                text-transform: capitalize;
                font-weight: 500;
                color: $black;
                font-size: 14px;
                line-height: 1em;
                margin-bottom: 8px;
            }
        }
        .page__content &-group {
            border: 0;
            box-shadow: none;
            .MuiCard {
                &Header-root {
                    padding-left: 0;
                    padding-right: 0;
                }
                &Header-action {
                    margin-right: 0;
                    button {
                        text-transform: lowercase;
                        text-decoration: underline;
                    }
                }
                &Content-root {
                    padding: 0;
                }
            }
        }
        .btn-update-photo {
            background-color: $white;
            border: none;
            border-radius: 20px;
            line-height: 36px;
            height: 36px;
            padding-left: 1em;
            padding-right: 1em;
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
            &:hover {
                background-color: $white;
                box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
                border: none;
            }
            .icon {
                background-color: $black;
            }
        }
        &-dialog {
            .MuiDialog {
                &Title-root {
                    background-color: $black;
                    color: $white;
                    text-align: center;
                    margin-bottom: 1em;
                    position: relative;
                    font-weight: normal;
                    .MuiIconButton-root {
                        color: $white;
                        position: absolute;
                        right: 24px;
                        top: 50%;
                        transform: translate(12px, -50%);
                    }
                }
                &-paper {
                    min-width: 450px;
                }
            }
            .MuiInputBase-root   {
                border-radius: 0;
            }
        }
    //    .informations--table {
    //         > div {
    //             display: flex;
    //             flex-direction: row;
    //             padding: 4px 4px;
    //             > div {
    //                 padding-left: 8px;
    //                 &:first-child {
    //                     flex-basis: 30%;
    //                     flex-shrink: 0;
    //                     flex-grow: 0;
    //                 }
    //             }
    //             &:nth-child(odd) {
    //                 background-color: $gray-blue-shade;
    //             }
    //             &:last-child {
    //                 border-bottom: 1px solid $gray;
    //             }
    //         }
    //    } 

        .MuiCardActions-root {
            padding: 0;
            button {
                min-width: 284px;
            }
        }
        .settings {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            .setting__item {
               flex-basis: calc((100% - 24px)/2); 
               flex-shrink: 1;
               flex-grow: 0;
               display: flex;
               flex-direction: column;
               flex-wrap: wrap;
               margin-bottom: 24px;
               label {
                text-transform: capitalize;
                font-weight: 500;
                color: $black;
                font-size: 14px;
                line-height: 1em;
                margin-bottom: 8px;
               }
               &--options {
                flex-basis: 100%;
                button {
                    width: 270px;
                    margin-right: 1em;
                }
               }
            }
            .setting__acount-status {
                button {
                    color: $black;
                }
                span::before {
                    content: " ";
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    background-color: #72C72C;
                    margin-right: 8px;
                    border-radius: 50%;
                }
            }
        }
    }

    @media screen and (max-width: 900px) {
        .setting__tabs {
            flex-direction: column!important;
            .MuiTabs-root {
                flex-basis: auto;
                margin-bottom: 1em;
            }
            div[role="tabpanel"] .MuiBox-root {
                padding: 0;
            }
        } 
        &__settings .cols {
            flex-direction: column!important;
        }
    }
}