@import "../../assets/scss/variables";

.verify-otp-dialog {
  .digits-row {
    &-boxes {
      justify-content: flex-start;

      .digits-row-digit {
        font-size: 45px;
        font-weight: normal;
        border-bottom-color: $border;
      }
    }
  }

  @media screen and (max-width: 560px) {
    .MuiPaper-root {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
    }
  }
}
